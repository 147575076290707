const {
  REACT_APP_BASE_URL,
  REACT_APP_VERSION,
  REACT_APP_ENV,
} = process.env;

const isProduction = REACT_APP_ENV === 'production';
const isDevelopment = REACT_APP_ENV === 'development';
const isLocal = REACT_APP_ENV === 'local';

const env = {
  REACT_APP_VERSION,
  REACT_APP_BASE_URL,
  isProduction,
  isDevelopment,
  isLocal,
};

export default env;
