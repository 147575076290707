import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { createGlobalStyle } from 'styled-components';

const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#7189de',
      main: '#3c5cac',
      dark: '#00337c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5983',
      main: '#f50057',
      dark: '#bb002f',
      contrastText: '#000',
    },
  },
}, ptBR);

export default Theme;

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f4f5f7;
  }
`;
