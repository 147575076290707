import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStylesSearch = makeStyles((theme: Theme) => createStyles({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default useStylesSearch;
