import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Nav = styled.nav`
  display: flex;
  min-width: 250px;
  width: 750px;  
  padding-left: 15px;
  padding-top: 15px;

  ul {
    margin: 10px 0 20px 20px;
    padding: 0;
    min-width: 225px;
  }

  ul:first-child {
    margin: 20px 0 20px 0;
  }

  ul > li {
    list-style-type: none;
  }

  li > span {
    color: rgba(45, 62, 94, 0.9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }
`;

export const InputLabelStyled = makeStyles({
  inputLabel: {
    backgroundColor: '#f4f5f7',
    color: '#3c5cac',
    paddingLeft: 10,
    paddingRight: 10,
  },
  selectItem: {
    width: '223px',
    marginTop: '10px',
  },
  divider: {
    margin: 10,
  },
});
