import {
  createSelector, createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { hello, Permissions } from './auth';
import { ApiCallBegan, apiCallBegan } from './api';
import toastMessage from '../services/toastMessage.services';

export interface User {
  id: number,
  created_at: string,
  name: string,
  email: string,
  status: 0 | 1,
  permissions: Permissions,
}

interface ApiResponse {
  status: string,
  message: string,
  response_data: Object,
}

interface LoadUserListResponse extends ApiResponse {
  response_data: {
    data: User[],
  }
}

interface LoadUserResponse extends ApiResponse {
  response_data: User,
}

interface UsersState {
  userList: User[],
  selectedUser?: User,
  loadingUsers: boolean,
}

const initialState: UsersState = {
  userList: [],
  loadingUsers: false,
};

const slice = createSlice(
  {
    name: 'api/users',
    initialState,
    reducers: {
      usersRequested: (users: Draft<UsersState>): void => {
        users.loadingUsers = true;
      },
      userListReceived: (
        users: Draft<UsersState>,
        action: PayloadAction<LoadUserListResponse>,
      ): void => {
        users.loadingUsers = false;
        users.userList = action.payload.response_data.data;
      },
      requestFailed: (users: Draft<UsersState>): void => {
        // failed request
        users.loadingUsers = false;
        users.userList = [];

        // alert
        const message = 'Não encontrado';
        toastMessage({
          types: 'INFO',
          message,
        });
      },
      userReceived: (
        users: Draft<UsersState>,
        action: PayloadAction<LoadUserResponse>,
      ): void => {
        users.loadingUsers = false;
        users.selectedUser = action.payload.response_data;
      },
      userUpdated: (
        users: Draft<UsersState>,
        action: PayloadAction<LoadUserResponse>,
      ): void => {
        users.loadingUsers = false;
        users.selectedUser = action.payload.response_data;
      },
      usersCleared: (users: Draft<UsersState>): void => {
        users.userList = [];
      },
    },
  },
);

export default slice.reducer;

const {
  usersRequested,
  usersCleared,
  userListReceived,
  userReceived,
  requestFailed,
} = slice.actions;

type tokenType = {
  readonly token: string,
}

interface LoadUserListParams {
  page: number,
  qtd: number,
  order_by?: 1 | 2, // ascending | descending
  status?: 0 | 1,
  search?: string,
}

export function loadUserList(params: LoadUserListParams) {
  return (dispatch: Function, getState: Function) => {
    const url = 'mng/users';

    let { token }: tokenType = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const request = {
      url,
      params,
      header: { Authorization: `Bearer ${token}` },
      onStart: usersRequested.type,
      onSuccess: userListReceived.type,
      onError: requestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

interface LoadUserParams {
  id: number,
}

export function loadUser(params: LoadUserParams) {
  return (dispatch: Function, getState: Function) => {
    const url = `mng/users/${params.id}`;

    let { token }: tokenType = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const request = {
      url,
      params,
      header: { Authorization: `Bearer ${token}` },
      onStart: usersRequested.type,
      onSuccess: userReceived.type,
      onError: requestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

interface UpdateUserParams {
  id: number,
  name: string,
  email: string,
  status: number,
  password?: string,
  permissions?: Permissions,
}

export function updateUser(data: UpdateUserParams) {
  return (dispatch: Function, getState: Function) => {
    const url = `mng/users/${data.id}`;

    let { token }: tokenType = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const request: ApiCallBegan = {
      url,
      method: 'put',
      data,
      header: { Authorization: `Bearer ${token}` },
      onStart: usersRequested.type,
      onSuccess: userReceived.type,
      onError: requestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

export function clearUsers() {
  return (dispatch: Dispatch): void => {
    dispatch(usersCleared());
  };
}

interface State {
  entities: {
    users: UsersState,
  }
}

export const getUsers = createSelector(
  (state: State) => state.entities.users,
  (users: UsersState) => users,
);
