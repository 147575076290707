import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import SyncIcon from '@material-ui/icons/Sync';
import { countSyncTotal, getSyncs } from '../../../store/synchronized/synchronized';
import { Container, Content } from './styles';

export function SynchronizedTotal() {
  const dispatch = useDispatch();

  // get values sync redux
  const {
    total,
    isFiltered,
    list,
    listFilter,
  } = useSelector(getSyncs);

  React.useEffect(() => {
    dispatch(countSyncTotal(isFiltered));
  }, [isFiltered, list, listFilter]);

  return (
    <Container>
      <Content>
        <Chip
          label={`Total ${total.total}`}
          color="primary"
          icon={<PeopleAltOutlinedIcon />}
          className="chip"
        />

        <Chip
          label={`Sincronizados ${total.synchronized}`}
          icon={<SyncIcon />}
          className="chip"
        />
        <Chip
          label={`Nunca sincronizados ${total.notSynchronized}`}
          color="secondary"
          variant="outlined"
          icon={<SyncProblemIcon />}
          className="chip"
        />
      </Content>
    </Container>
  );
}
