import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip, Typography } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import TableChangelog from './TableChangelog';
import clipboard from '../../../services/api/api.clipboard';
import useRowStyles from '../styles/Update.module';

export default function CollapsibleTable(props: {changelog: string, open: boolean}) {
  const classes = useRowStyles();
  const { changelog, open } = props;

  return (
    <TableRow>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        colSpan={6}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell className={`${classes.tableCell} ${classes.start}`}>
                    <Typography
                      style={{ paddingTop: '12px' }}
                    >
                      Changelog
                    </Typography>
                    <FeedbackIcon className={classes.versionIcon} style={{ marginTop: '15px' }} />
                    <Tooltip title="COPIAR CHANGELOG">
                      <IconButton
                        onClick={() => {
                          clipboard({ copy: changelog });
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        <FileCopyIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableChangelog
                    description={changelog}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
