import React from 'react';
import { SearchEmail } from './SearchEmail';
import { DataGridView } from './DataGridView';
import { Main } from '../../components/Main';

export function OfficeUser() {
  return (
    <Main
      title="Usuário por escritórios"
      header={<SearchEmail />}
      content={<DataGridView />}
    />
  );
}
