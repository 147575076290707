import React from 'react';
import {
  DividerProps, IconButtonProps, InputBase, InputBaseProps, Paper, PaperProps, withStyles,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 16px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
`;

export const CustomPaper = withStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 450,
  },
})((props: PaperProps) => <Paper {...props} />);

export const InputBaseStyled = withStyles((theme: Theme) => (
  {
    root: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      display: 'flex',
    },
  }
))(
  (props: InputBaseProps) => <InputBase {...props} />,
);

export const VerticalDivider = withStyles({
  root: {
    height: 28,
    margin: 4,
  },
})(
  (props: DividerProps) => <Divider {...props} orientation="vertical" />,
);

export const IconButtonStyled = withStyles({
  root: {
    padding: 10,
  },
})((props: IconButtonProps) => <IconButton {...props} />);
