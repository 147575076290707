import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Header,
  Paper,
  Description,
  Title,
  ButtonGroup,
  Content,
  useModalStyles,
} from './styles';

interface ModalStatusProps {
  title: string;
  description: string | undefined;
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  name: string | undefined;
}

export function ModalStatus(props: ModalStatusProps) {
  const classes = useModalStyles();

  const {
    open,
    description,
    title,
    name,
    handleClose,
    handleOpen,
  } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper>
            <Header>
              <Title>
                {title}
              </Title>
              <IconButton
                style={{
                  width: 30,
                  height: 30,
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Header>
            <Divider />
            <Content>
              <Description>
                {`${description} `}
                <span style={{ fontWeight: 'bold' }}>{`${name} ?`}</span>
              </Description>
            </Content>
            <Divider />
            <ButtonGroup>
              <Button
                variant="contained"
                onClick={handleOpen}
                disableElevation
              >
                Sim
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClose}
                style={{
                  marginLeft: '10px',
                  color: '#fff',
                }}
                disableElevation
              >
                Não
              </Button>
            </ButtonGroup>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
