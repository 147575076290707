import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import Theme, { GlobalStyle } from '../../styles/global';
import Routes from '../../routes';

export const App: React.FC = () => (
  <MuiThemeProvider theme={Theme}>
    <Routes />
    <GlobalStyle />
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick
      pauseOnHover={false}
    />
  </MuiThemeProvider>
);
