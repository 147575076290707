import React, { useState } from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { User } from '../../../store/users';

type ConfirmParams = {
  id: number,
  name: string,
  email: string,
  status: number,
  password: string,
}

interface EditDialogProps {
  dialogProps: DialogProps;
  userBeingEdited: User;
  onConfirm: (user: ConfirmParams) => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export function ChangePasswordDialog(
  {
    dialogProps,
    userBeingEdited,
    onConfirm,
    onCancel,
  }: EditDialogProps,
) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    onConfirm(
      {
        id: userBeingEdited.id,
        name: userBeingEdited.name,
        email: userBeingEdited.email,
        status: userBeingEdited.status,
        password,
      },
    );
  };

  const handleCancel = () => {
    onCancel();
    setPassword('');
    setPasswordConfirm('');
    setShowPassword(false);
    setShowPasswordConfirm(false);
    setError(false);
  };

  const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);

    if (password !== event.target.value || (!password || !event.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <Dialog {...dialogProps} maxWidth="sm" fullWidth>
      <DialogTitle>Alterar senha</DialogTitle>
      <DialogContent>
        <Box className={classes.flex}>
          <TextField
            variant="outlined"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              // eslint-disable-next-line max-len
              endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>,
            }}
          />
          <TextField
            variant="outlined"
            label="Confirmação de senha"
            type={showPasswordConfirm ? 'text' : 'password'}
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
            InputProps={{
              // eslint-disable-next-line max-len
              endAdornment: <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>{showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>,
            }}
            error={error}
            helperText={error ? 'As senhas precisam ser iguais' : undefined}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary" disabled={error || (!password || !passwordConfirm)}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}
