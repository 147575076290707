import React from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import { Button, Tooltip, Zoom } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import LoadingDataGrid from '../../../components/LoadingDataGrid';
import { getVersions } from '../../../store/versions';

const VersionColumnList: GridColDef[] = [
  {
    field: 'CLIENTE',
    headerName: 'Cliente',
    width: 250,
    renderCell: (params: GridCellParams) => {
      const description = params.value as string;
      const isLargeDescription = description.length < 20;
      return (
        <Tooltip
          placement="bottom"
          TransitionComponent={Zoom}
          title={description}
        >
          <p style={{ textTransform: 'uppercase' }}>
            {isLargeDescription
              ? description
              : `${description.substr(0, 20)}...`}
          </p>
        </Tooltip>
      );
    },
  },
  {
    field: 'VERSAO',
    headerName: 'Versão',
    width: 150,
    renderCell: (params: GridCellParams) => {
      const description = params.value as string;
      return (
        <Button
          startIcon={<InfoIcon style={{ fill: '#607d8b' }} />}
        >
          <p>{description}</p>
        </Button>
      );
    },
  },
  {
    field: 'DATA',
    headerName: 'Data',
    width: 150,
    renderCell: (params: GridCellParams) => {
      const date = moment(new Date(params.value as string))
        .format('DD/MM/YYYY HH:mm');
      return (<p>{date}</p>);
    },
  },
];

export default function VersionDataGrid() {
  const {
    listVersion,
    loadingVersion,
  } = useSelector(getVersions);

  return (
    <DataGrid
      components={{
        LoadingOverlay: LoadingDataGrid,
      }}
      disableColumnMenu
      disableDensitySelector
      disableSelectionOnClick
      autoHeight
      rows={listVersion}
      columns={VersionColumnList}
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      loading={loadingVersion}
    />
  );
}
