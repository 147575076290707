import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

export default function CustomBadge(props: { badgeContent: number, icon: React.ReactNode }) {
  const classes = useStyles();
  const { badgeContent, icon } = props;

  const defaultProps = {
    children: icon,
  };

  return (
    <div className={classes.root}>
      <Badge
        badgeContent={badgeContent}
        max={9999}
        color="primary"
        {...defaultProps}
      />
    </div>
  );
}
