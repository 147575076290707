import {
  createSelector, createSlice, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { ApiCallBegan, apiCallBegan } from '../api';
import { hello } from '../auth';
import {
  ResponseDataSync,
  SynchronizedData,
  SynchronizedTotal,
  SyncRequest,
  SyncsReceived,
} from './types';

const initialState = {
  list: [],
  listDetails: [],
  listFilter: [],
  isFiltered: false,
  loading: false,
  lastFetch: 0,
  total: {} as SynchronizedTotal,
};

const slice = createSlice({
  name: 'synchronized',
  initialState,
  reducers: {
    // actions => actions handlers
    syncsRequested: (sync: Draft<SyncRequest>): void => {
      sync.loading = true;
    },
    syncsRequestFailed: (sync: Draft<SyncRequest>): void => {
      sync.loading = false;
    },
    /* syncs/syncsReceived */
    syncsReceived: (sync: Draft<SyncsReceived>, action: PayloadAction<any>): void => {
      /*
      * adding id to the list, for the data grid, is required -
      *  including the values of the response data
      */
      const { response_data } = action.payload;

      sync.list = response_data.map((data: ResponseDataSync, index: number) => {
        data.id = index;
        return data;
      });

      sync.loading = false;
      sync.lastFetch = Date.now();
    },
    syncsCountTotal: (
      sync: Draft<SyncsReceived>,
      action: PayloadAction<SynchronizedTotal>,
    ): void => {
      sync.total = action.payload;
    },
    syncsDetailsRequested: (sync: Draft<SyncRequest>): void => {
      sync.loading = true;
    },
    syncsDetailsRequestFailed: (sync: Draft<SyncRequest>): void => {
      sync.loading = false;
    },

    syncsDetailsReceived: (sync: Draft<SyncsReceived>, action: PayloadAction<any>): void => {
      sync.listDetails = action.payload.response_data;
      sync.loading = false;
      sync.lastFetch = Date.now();
    },
    syncListDetailsClear: (sync: Draft<SyncsReceived>): void => {
      sync.listDetails = [];
    },
    syncFilterOnStart: (sync: Draft<SyncsReceived>): void => {
      sync.loading = true;
    },
    syncFilterOnSuccess: (sync: Draft<SyncsReceived>, action: PayloadAction<any>): void => {
      sync.loading = false;
      sync.isFiltered = true;
      sync.listFilter = action.payload;
    },
    syncFilterClear: (sync: Draft<SyncsReceived>): void => {
      sync.loading = false;
      sync.isFiltered = false;
    },
  },
});

const {
  syncsRequested,
  syncsRequestFailed,
  syncsReceived,
  syncsDetailsReceived,
  syncListDetailsClear,
  syncsDetailsRequested,
  syncsDetailsRequestFailed,
  syncFilterOnStart,
  syncFilterOnSuccess,
  syncFilterClear,
  syncsCountTotal,
} = slice.actions;

export default slice.reducer;

// Action Creators
const url = 'clients/sync';

// Cache

type tokenType = {
  readonly token: string,
}

export const loadSyncs = () => (dispatch: Function, getState: Function) => {
  // get token
  let { token }: tokenType = getState().entities.auth;

  // if you don't have a token, get the session token
  if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

  // api request for list synchronized - action
  const request = {
    url: `${url}/report`,
    header: { Authorization: `Bearer ${token}` },
    onStart: syncsRequested.type,
    onSuccess: syncsReceived.type,
    onError: syncsRequestFailed.type,
  };

  dispatch(
    apiCallBegan(request),
  );
};

export const getSyncDetails = (date: string) => (dispatch: Function, getState: Function) => {
  // get token
  let { token } = getState().entities.auth;

  // if you don't have a token, get the session token
  if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

  // returning null for the api request, the previous value was string
  const getDate: string | null = date === 'neverSynced' ? null : date;

  // clears the detailed sync list
  dispatch(syncListDetailsClear());

  // api request for detailed list - action
  const request: ApiCallBegan = {
    url: `${url}/search/${getDate}`,
    header: { Authorization: `Bearer ${token}` },
    onSuccess: syncsDetailsReceived.type,
    onError: syncsDetailsRequestFailed.type,
    onStart: syncsDetailsRequested.type,
  };
  dispatch(apiCallBegan(request));
};

function synchronizedSummary(list: SynchronizedData[]): SynchronizedTotal {
  return list.reduce((acc: SynchronizedTotal, synchronized: SynchronizedData) => {
    if (synchronized.date !== null) {
      acc.synchronized += synchronized.count;
      acc.total += synchronized.count;
    } else {
      acc.notSynchronized += synchronized.count;
      acc.total += synchronized.count;
    }
    return acc;
  }, {
    total: 0,
    synchronized: 0,
    notSynchronized: 0,
  });
}

export const countSyncTotal = (isFiltered: boolean) => (dispatch: Function, getState: Function) => {
  const {
    listFilter,
    list,
  } = getState().entities.synchronized;

  if (isFiltered) {
    const summary = synchronizedSummary(listFilter);
    dispatch(syncsCountTotal(summary));
  } else {
    const summary = synchronizedSummary(list);
    dispatch(syncsCountTotal(summary));
  }
};

export function filterSyncs(datePicker: Date | null) {
  return (dispatch: Function, getState: Function) => {
    dispatch(syncFilterOnStart());

    const { list } = getState().entities.synchronized;

    function filterDate(syncList: ResponseDataSync) {
      const { date } = syncList;
      // comparing the data picker date with the date of the sync list
      return moment(datePicker)
        .isAfter(new Date(date));
    }

    const filtered = list.filter(filterDate);

    setTimeout(() => {
      dispatch(syncFilterOnSuccess(filtered));
    }, 500);
  };
}

export function filterClear() {
  return (dispatch: Function) => {
    dispatch(syncFilterOnStart());

    setTimeout(() => {
      dispatch(syncFilterClear());
    }, 500);
  };
}

/* Selectors */

interface Sync {
  loading: boolean,
  isFiltered: boolean,
  list: [],
  listDetails: [],
  listFilter: [],
  total: SynchronizedTotal,
}

interface State {
  entities: {
    synchronized: Sync
  }
}

export const getSyncs = createSelector(
  (state: State) => state.entities.synchronized,
  (synchronized: Sync) => synchronized,
);
