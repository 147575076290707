import { Typography } from '@material-ui/core';
import React from 'react';
import { cnpjFormat } from '../../lib/cnpj';

interface TypographyCnpjProps {
  cnpj: string | undefined;
}

const TypographyCnpj = ({ cnpj }: TypographyCnpjProps) => {
  const cnpjMasked = cnpjFormat(cnpj);

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
    >
      <strong>CNPJ: </strong>
      {!cnpj && 'não informado'}
      {cnpj && `${cnpjMasked}`}
    </Typography>
  );
};

export default TypographyCnpj;
