import {
  Box, Button, Card, Collapse, Divider, IconButton, InputBase, Link, Paper,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPageChangeParams,
} from '@material-ui/data-grid';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Main } from '../../components/Main';
import { getUsers, loadUserList } from '../../store/users';
import useStyles from './styles/User.module';
import UserFilter from './UsersFilterOptions';
import { orderByUserFormControl, statusUserFormControl } from './UsersFilterOptions/usersFilterOptions';

interface RequestParams {
  page: number,
  qtd: number,
  order_by?: 1 | 2,
  status?: 0 | 1,
  search?: string,
}

interface Filters {
  order_by: -1 | 1 | 2,
  status: -1 | 0 | 1,
  search: string,
}

const initialParams: RequestParams = {
  page: 1,
  qtd: 10,
};

const initialFilters: Filters = {
  order_by: -1,
  status: -1,
  search: '',
};

export default function Users() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { userList, loadingUsers } = useSelector(getUsers);

  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const [requestParams, setRequestParams] = useState<RequestParams>(initialParams);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Ativo',
      renderCell: (params: GridCellParams) => (
        <>
          {params.value ? 'Sim' : 'Não'}
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params: GridCellParams) => (
        <Link component={RouterLink} to={`/users/${params.row.id}`}>Ver detalhes</Link>
      ),
      width: 150,
    },
  ];

  useEffect(() => {
    dispatch(loadUserList(requestParams));
  }, []);

  useEffect(() => {
    dispatch(loadUserList(requestParams));
  }, [requestParams]);

  const handleFilterChange = (option: number | string, key: keyof Filters): void => {
    setFilters({
      ...filters,
      [key]: option,
    });
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  // returns a new filters object without the properties with value equal to -1 and ''
  const removeOptionals = (obj: Filters) => {
    const noOptionals = Object.keys(obj).filter((key) => obj[key as keyof Filters] !== -1 && obj[key as keyof Filters] !== '')
      .reduce((newObj, key) => ({ ...newObj, [key]: obj[key as keyof Filters] }), {});

    return noOptionals;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params: RequestParams = { ...requestParams, ...removeOptionals(filters) };

    dispatch(loadUserList(params));
  };

  const handlePageChange = (pageChange: GridPageChangeParams) => {
    setRequestParams({ ...requestParams, page: pageChange.page + 1, qtd: pageChange.pageSize });
  };

  return (
    <Main
      title="Usuários"
      header={(
        <form onSubmit={handleSubmit}>
          <Box component={Paper} py={0.25} px={0.5} m={2} display="flex" alignItems="center">
            <InputBase
              placeholder="Busque nome ou email"
              className={classes.input}
              value={filters.search}
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
            />

            <Divider orientation="vertical" className={classes.divider} />

            <IconButton
              type="submit"
              aria-label="buscar"
            >
              <SearchIcon />
            </IconButton>

            <Divider orientation="vertical" className={classes.divider} />

            <Button
              color={isFilterExpanded ? 'primary' : 'default'}
              startIcon={<FilterListIcon />}
              onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            >
              Filtros
            </Button>
          </Box>
          <Collapse in={isFilterExpanded} style={{ width: '100%' }}>
            <Card style={{ width: '100%' }}>
              <Box display="flex" px={1} pt={1} pb={1.5} style={{ width: '720px' }}>
                <UserFilter
                  label="Status"
                  onChange={handleFilterChange}
                  nameLabel="status"
                  formControl={statusUserFormControl}
                  value={filters.status}
                />
                <UserFilter
                  label="Ordenar por id"
                  onChange={handleFilterChange}
                  nameLabel="order_by"
                  formControl={orderByUserFormControl}
                  value={filters.order_by}
                />
              </Box>

              <Divider />

              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ margin: '10px' }}
                onClick={resetFilters}
              >
                Limpar
              </Button>
            </Card>
          </Collapse>
        </form>
      )}
      content={(
        <DataGrid
          rows={userList}
          columns={columns}
          disableColumnMenu
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading={loadingUsers}
          onPageSizeChange={handlePageChange}
          onPageChange={handlePageChange}
        />
      )}
    />
  );
}
