import {
  createSelector, createSlice, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { hello } from '../auth';
import { apiCallBegan } from '../api';

interface SoftwareAll {
    id : number;
    name: string;
}

interface SoftwareList {
    listSoftware: SoftwareAll[],
    loadingSoftware: boolean,
}

const initialState = {
  listSoftware: [],
  loadingSoftware: false,
};

const slice = createSlice(
  {
    name: 'api/software/all',
    initialState,
    reducers: {
      listRequested: (software: Draft<SoftwareList>): void => {
        software.loadingSoftware = true;
      },
      listRequestFailed: (software: Draft<SoftwareList>): void => {
        software.loadingSoftware = false;
      },
      listReceived: (software: Draft<SoftwareList>, action: PayloadAction<any>): void => {
        software.loadingSoftware = false;
        software.listSoftware = action.payload.response_data;
      },
    },
  },
);

export default slice.reducer;

const {
  listRequested,
  listRequestFailed,
  listReceived,
} = slice.actions;

type tokenType = {
    readonly token: string,
}

const url = 'software/all';

export const loadSoftwareList = () => (dispatch: Function, getState: Function) => {
  // get token
  let { token }: tokenType = getState().entities.auth;

  // if you don't have a token, get the session hello
  if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

  // api request for list software - action
  const request = {
    url,
    header: { Authorization: `Bearer ${token}` },
    onStart: listRequested.type,
    onSuccess: listReceived.type,
    onError: listRequestFailed.type,
  };

  dispatch(
    apiCallBegan(request),
  );
};

interface State {
    software: {
        all: SoftwareList
    }
}

export const getSoftwareList = createSelector(
  (state: State) => state.software.all,
  (all: SoftwareList) => all,
);
