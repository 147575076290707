import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React from 'react';
import { useDispatch } from 'react-redux';
import { downloadReportSat } from '../../../store/download';

export function Header() {
  const date = new Date();
  const initialState = new Date(date.setMonth(date.getMonth() - 1));

  const [selectedDate, handleDateChange] = React.useState<Date | null>(initialState);

  const dispatch = useDispatch();

  function handleDownload() {
    if (!selectedDate) return;

    const month = (selectedDate.getMonth() + 1);
    const year = selectedDate?.getFullYear();

    dispatch(downloadReportSat(month, year));
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        m={1}
        css={{ height: 100 }}
      >
        <Box p={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <DatePicker
              inputVariant="outlined"
              views={['year', 'month']}
              label="Ano e mês"
              minDateMessage="A data não deve ser anterior à data mínima "
              minDate={new Date('2015-01-01')}
              maxDate={initialState}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box p={1}>
          <Button
            onClick={handleDownload}
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            disableElevation
          >
            Relatório
          </Button>
        </Box>
      </Box>
    </>
  );
}
