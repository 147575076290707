import Grid from '@material-ui/core/Grid';
import { Box, LinearProgress } from '@material-ui/core';
import React from 'react';

export function LoginProgressIndicator() {
  return (
    <Grid
      container
      spacing={0}
      justify="center"
    >
      <Box p={4}>
        <Grid item style={{ width: '50vw' }}>
          <LinearProgress />
        </Grid>
      </Box>

    </Grid>
  );
}
