import toastMessage from '../toastMessage.services';

interface apiClipboard {
  copy: string,
}

export default function clipboard(props: apiClipboard) {
  const { copy } = props;
  navigator.clipboard.writeText(copy)
    .then(() => toastMessage({ types: 'INFO', message: 'Copiado para a área de transferência' }))
    .catch(() => toastMessage({ types: 'ERROR', message: 'Ops! ocorreu um erro!' }));
}
