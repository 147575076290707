import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Value } from '@material-ui/lab/useAutocomplete/useAutocomplete';

type AutocompleteProps = {
  id?: number,
  name?: string,
  branch?: string,
  version?: string,
}

interface ComboBoxAsynchronousProps {
  list: AutocompleteProps[];
  defaultValue?: AutocompleteProps;
  onChange: Function;
  loading: boolean;
  label?: string;
  id: string;
}

export default function ComboBoxAsynchronous(props: ComboBoxAsynchronousProps) {
  const {
    list,
    loading,
    onChange,
    label,
    id,
    defaultValue,
  } = props;

  const [open, setOpen] = React.useState(false);

  function getSoftwareId(e: React.ChangeEvent<{}>, getValue: Value<{}, false, false, false>) {
    onChange(getValue as AutocompleteProps);
  }

  function getOptionLabel(lists: AutocompleteProps) {
    const {
      name,
      branch,
    } = lists;
    if (name) return name;
    if (branch) return branch;
    return '';
  }

  function getOptionSelected(lists: AutocompleteProps, value: AutocompleteProps | '') {
    const {
      name,
      branch,
    } = lists;
    if (typeof value === 'string') return value === '';
    if (name) return name === value.name;
    if (branch) return branch === value.branch;
    return false;
  }

  return (
    <div style={{
      width: 200,
      margin: '8px 10px 0 0',
    }}
    >
      <Autocomplete
        id={id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={getSoftwareId}
        defaultValue={defaultValue}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={[...list]}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

ComboBoxAsynchronous.defaultProps = {
  label: 'Lista de Softwares',
  defaultValue: '',
};
