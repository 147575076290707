import React, { ChangeEvent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomPaper, Content, IconButtonStyled, InputBaseStyled, VerticalDivider, Container,
} from './styles';
import SelectStatus from '../SelectStatus';
import { getOfficeUser, getOfficeUsers } from '../../../store/officeUser';
import { OfficeUserParams } from '../../../store/officeUser/types';

export function SearchEmail() {
  const [email, setEmail] = React.useState<string>('');
  const [params, setParams] = React.useState<OfficeUserParams>({ status: 1 });

  const dispatch = useDispatch();

  // get status update
  const { status } = useSelector(getOfficeUsers);

  const changeEmail = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value as string);

    setParams({
      ...params,
      email: event.target.value as string,
    });
  };

  // clear values in input email
  function handleClearSearchValue() {
    if (params?.email) {
      setEmail('');
      // delete params email
      delete params.email;
    }
  }

  // get office users

  const handleSearchUser = () => {
    dispatch(getOfficeUser(params));
  };

  React.useEffect(() => {
    // taking the status after updating and updating the parameters
    setParams({
      ...params,
      status,
    });
  }, [status]);

  React.useEffect(() => {
    // dispatch only when the status parameter is updated
    dispatch(getOfficeUser(params));
  }, [params.status]);

  return (
    <Container>
      <CustomPaper>
        <InputBaseStyled
          placeholder="informe um email"
          inputComponent="input"
          type="email"
          value={email}
          onChange={changeEmail}
        />
        <Content>
          {params?.email && (
          <IconButtonStyled
            onClick={handleClearSearchValue}
          >
            <CloseIcon />
          </IconButtonStyled>
          )}
          <VerticalDivider />
          <SelectStatus />
          <IconButtonStyled
            onClick={handleSearchUser}
          >
            <SearchIcon />
          </IconButtonStyled>
        </Content>
      </CustomPaper>
    </Container>
  );
}
