import React from 'react';
import {
  BrowserRouter, Redirect, Route, Router, Switch,
} from 'react-router-dom';
import Root from './pages/Root';
import history from './services/history.services';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';

const Routes = () => (
  <BrowserRouter>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/">
          <Redirect to="/customers" />
        </Route>
        <PrivateRoute path="/">
          <Root />
        </PrivateRoute>
      </Switch>
    </Router>
  </BrowserRouter>
);

export default Routes;
