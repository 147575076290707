import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  clearErrorList,
  getDateSoftware, getErrorPerSystem,
} from '../../../store/errorsPerSystem';
import { downloadReportErrors } from '../../../store/download';
import { getSoftwareList, loadSoftwareList } from '../../../store/software/softwareAll';
import ComboBoxAsynchronous from '../../../components/ComboBoxAsynchronous';
import { ValuesAutocomplete } from './types';

export default function ErrorsPerSystemHeader() {
  const sislojaId = 1;
  const initialState = new Date(moment()
    .format('L'));

  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(initialState);
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(initialState);
  const [selectedSoftwareId, setSelectSoftwareId] = React.useState<number | null>(sislojaId);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadSoftwareList());
  }, []);

  const {
    listSoftware,
    loadingSoftware,
  } = useSelector(getSoftwareList);
  const { errorList } = useSelector(getErrorPerSystem);

  function getValuesAutocomplete(getValue: ValuesAutocomplete) {
    if (getValue !== null) {
      dispatch(clearErrorList());
      const { id } = getValue as ValuesAutocomplete;
      setSelectSoftwareId(id);
    } else {
      setSelectSoftwareId(null);
      dispatch(clearErrorList());
    }
  }

  function handleDateInitialChange(date: Date | null) {
    setSelectedFromDate(date);
  }

  function handleDateEndChange(date: Date | null) {
    setSelectedToDate(date);
  }

  function handleSoftware() {
    if (selectedFromDate && selectedToDate && selectedSoftwareId) {
      dispatch(getDateSoftware(selectedFromDate, selectedToDate, selectedSoftwareId));
    }
  }

  function handleDownload() {
    if (selectedFromDate && selectedToDate && selectedSoftwareId) {
      dispatch(downloadReportErrors(selectedFromDate, selectedToDate, selectedSoftwareId));
    }
  }

  if (listSoftware.length === 0) {
    return (
      <p>
        ops! menu indisponível,
        tente recarregar a página em alguns
        minutos.
      </p>
    );
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 100,
      width: 900,
    }}
    >
      {listSoftware.length > 0 ? (
        <ComboBoxAsynchronous
          loading={loadingSoftware}
          list={listSoftware}
          onChange={getValuesAutocomplete}
          id="software-list"
          defaultValue={listSoftware.find((software) => software.id === sislojaId)}
        />
      )
        : (
          <ComboBoxAsynchronous
            loading={loadingSoftware}
            list={listSoftware}
            onChange={getValuesAutocomplete}
            id="software-list"
          />
        )}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <div style={{ width: 200 }}>
          <KeyboardDatePicker
            disableToolbar
            variant="dialog"
            inputVariant="filled"
            format="dd/MM/yyyy"
            margin="normal"
            id="data_inicial"
            label="Data inicial"
            value={selectedFromDate}
            onChange={handleDateInitialChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
        <div style={{ width: 200 }}>
          <KeyboardDatePicker
            disableToolbar
            variant="dialog"
            inputVariant="filled"
            format="dd/MM/yyyy"
            margin="normal"
            id="data_final"
            label="Data final"
            value={selectedToDate}
            onChange={handleDateEndChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
      <div>
        <Button
          onClick={handleSoftware}
          disabled={!selectedSoftwareId}
          variant="contained"
          startIcon={<SearchIcon />}
          disableElevation
          color="primary"
        >
          Buscar
        </Button>
      </div>
      {errorList.length > 0
        && (
          <div>
            <Button
              onClick={handleDownload}
              disabled={!selectedSoftwareId}
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              disableElevation
            >
              Relatório
            </Button>
          </div>
        )}
    </div>
  );
}
