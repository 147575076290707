import {
  Box,
  Checkbox,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
} from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Main } from '../../components/Main';
import { cnpjFormat } from '../../lib/cnpj';
import { selectorAuth } from '../../store/auth';
import { fetchCustomer, getCustomers, updateCustomerModules } from '../../store/customers';

type Module = {
  id: number,
  value: number,
  software_module: {
    id: number,
    software_id: number,
    description: string,
  },
  software: {
    id: number,
    name: string,
    download_path: string,
  },
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  fab: {
    margin: 0,
    top: 'auto',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  bold: {
    fontWeight: 'bold',

    '& span': {
      fontWeight: 'normal',
    },
  },
}));

export const CustomerModules: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string | undefined }>();

  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(getCustomers);
  const { permissions } = useSelector(selectorAuth);

  const [modules, setModules] = useState<Module[]>([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomer({ id }));
    }
  }, []);

  useEffect(() => {
    setShowConfirmButton(JSON.stringify(modules) !== JSON.stringify(selectedCustomer?.modules));
  }, [modules]);

  useEffect(() => {
    if (selectedCustomer) {
      setModules(selectedCustomer.modules);
    }
  }, [selectedCustomer]);

  if (!selectedCustomer || !selectedCustomer.modules) {
    return null;
  }

  const gridData = modules.map((item) => (
    {
      id: item.id,
      value: item.value,
      description: item.software_module.description,
    }
  ));

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, moduleId: number) {
    if (selectedCustomer && permissions.update_client_modules === 1) {
      const updatedModules: Module[] = modules.map((module) => {
        if (module.id === moduleId) {
          return {
            ...module,
            value: event.target.checked ? 1 : 0,
          };
        }
        return module;
      });

      setModules([...updatedModules]);
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: 'Ativo',
      renderCell: (params: GridCellParams) => (
        <>
          <Checkbox
            checked={params.value === 1}
            name="isActive"
            color="primary"
            onChange={(event) => handleChange(event, params.row.id as number)}
            disabled={permissions.update_client_modules === 0}
          />
        </>
      ),
    },
    {
      field: 'description',
      headerName: 'Módulo',
      width: 200,
      flex: 1,
    },
  ];

  function handleConfirm() {
    dispatch(updateCustomerModules({ id: Number(id), modules }));
  }

  return (
    <>
      {permissions.update_client_modules === 1 && showConfirmButton
        && <Fab variant="extended" color="primary" onClick={handleConfirm} className={classes.fab}>Salvar alterações</Fab>}
      <Main
        title={selectedCustomer ? selectedCustomer.fantasy_name : ''}
        header={(
          <Box textAlign="left" width="100%" pl={2.5} pt={4}>
            <Typography className={classes.bold}>
              Cadastro:
              {' '}
              <span>
                {
                  new Date(selectedCustomer.created_at).toLocaleDateString(
                    'pt-BR',
                    { day: '2-digit', month: '2-digit', year: 'numeric' },
                  )
                }
              </span>
            </Typography>
            <Typography className={classes.bold}>
              CNPJ:
              {' '}
              <span>{cnpjFormat(selectedCustomer.cnpj)}</span>
            </Typography>
            <Typography className={classes.bold}>
              IE:
              {' '}
              <span>{selectedCustomer.ie}</span>
            </Typography>
          </Box>
        )}
        content={(
          <>

            <DataGrid
              disableColumnMenu
              disableDensitySelector
              disableSelectionOnClick
              autoHeight
              rows={gridData}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </>
        )}
      />
    </>
  );
};
