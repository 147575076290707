import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import clipboard from '../../services/api/api.clipboard';
import { CopyButtonProps } from './types';

export default function CopyButton({ copy }: CopyButtonProps) {
  return (
    <Tooltip title="Copiar para a área de transferência">
      <IconButton onClick={() => clipboard({ copy })}>
        <FileCopyIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
