import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getVersions } from '../../../store/versions';
import VersionGraphicItem from './VersionGraphicItem';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  contentGraphic: {
    display: 'flex',
    justifyContent: 'center',
  },
  graphicItem: {
    marginLeft: '20px',
    width: '500px',
  },
}));

export default function VersionGraphics() {
  const classes = useStyles();

  const {
    listQtt,
    listQttDate,
    loadingQtt,
  } = useSelector(getVersions);

  return (
    <div className={classes.contentGraphic}>

      <div className={classes.graphicItem}>

        {loadingQtt && (
        <div className={classes.root}>
          <LinearProgress />
        </div>
        )}

        {!loadingQtt && listQttDate.length > 0 && (
        <VersionGraphicItem
          list={listQttDate}
          title="Data anterior"
        />
        )}
      </div>

      <div className={classes.graphicItem}>

        {loadingQtt && (
        <div className={classes.root}>
          <LinearProgress />
        </div>
        )}

        {!loadingQtt && listQttDate.length > 0 && (
        <VersionGraphicItem
          list={listQtt}
          title="Data atual"
        />
        )}
      </div>

    </div>
  );
}
