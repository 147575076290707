import {
  createSelector, createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { hello } from '../auth';
import { apiCallBegan } from '../api';
import toastMessage from '../../services/toastMessage.services';

interface SoftwareBranch {
    id : number;
    name: string;
}

interface SoftwareBranchList {
    listBranch: SoftwareBranch[],
    loadingBranch: boolean,
}

const initialState = {
  listBranch: [],
  loadingBranch: false,
};

const slice = createSlice(
  {
    name: 'api/software/branch',
    initialState,
    reducers: {
      branchRequested: (software: Draft<SoftwareBranchList>): void => {
        software.loadingBranch = true;
      },
      branchRequestFailed: (software: Draft<SoftwareBranchList>, action: PayloadAction<any>) => {
        software.loadingBranch = false;
        const message = action.payload;

        toastMessage({ message, types: 'INFO' });
      },
      branchReceived: (software: Draft<SoftwareBranchList>, action: PayloadAction<any>): void => {
        software.loadingBranch = false;
        software.listBranch = action.payload.response_data;
      },
      branchClear: (software: Draft<SoftwareBranchList>): void => {
        software.listBranch = [];
      },
    },
  },
);

export default slice.reducer;

const {
  branchRequested,
  branchRequestFailed,
  branchReceived,
  branchClear,
} = slice.actions;

type tokenType = {
    readonly token: string,
}

const url = 'software/listBranchs';

interface LoadSoftwareBranch {
    software_id: number
}

export function loadSoftwareBranch(params: LoadSoftwareBranch) {
  return (dispatch: Function, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    // api request for list software - action
    const request = {
      url,
      params,
      header: { Authorization: `Bearer ${token}` },
      onStart: branchRequested.type,
      onSuccess: branchReceived.type,
      onError: branchRequestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

export function branchClearData() {
  return (dispatch: Dispatch): void => {
    dispatch(branchClear());
  };
}

interface State {
    software: {
        branch: SoftwareBranchList
    }
}

export const getSoftwareBranch = createSelector(
  (state: State) => state.software.branch,
  (branch: SoftwareBranchList) => branch,
);
