import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import 'date-fns';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Nav, InputLabelStyled } from './styles';
import {
  DocumentSentParams,
  HandleChangeProps,
  SelectParams,
  SelectParamsKey,
} from './types';
import DataGridDocumentSent from './DataGridDocumentSent';
import {
  getDocumentSent,
  getDocumentSentData,
  getDocumentSentDate,
} from '../../store/documentSent';
import { Main } from '../../components/Main';

const date = new Date();
const initialStateDate = new Date(date.setMonth(date.getMonth() - 1));

const initialState: DocumentSentParams = {
  page: 1,
  qtd: 10,
  month: initialStateDate.getMonth() + 1,
  year: initialStateDate.getFullYear(),
};

const initialStateSelected = {
  document_type: -1,
  sent: -1,
  errors: -1,
};

export function DocumentSent() {
  const classes = InputLabelStyled();
  const dispatch = useDispatch();

  // select
  const [selected, setSelected] = React.useState<SelectParams>(initialStateSelected);

  // params
  // eslint-disable-next-line max-len
  const [documentSentParams, setDocumentSentParams] = React.useState<DocumentSentParams>(initialState);

  const handleChange = (event: React.ChangeEvent<HandleChangeProps>) => {
    const key: SelectParamsKey = event.target.name as SelectParamsKey;
    const value: number = Number(event.target.value);

    // add new values for select
    setSelected({
      ...selected,
      [key]: value,
    });

    // add and remove item params
    if (value === -1) {
      delete documentSentParams[key];
    } else {
      setDocumentSentParams({
        ...documentSentParams,
        [key]: value,
        page: 1,
      });
    }
  };

  // date picker
  // TODO add values text input masked and remove key and values
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(initialStateDate);

  function handleDateChange(dates: Date | null) {
    setSelectedDate(dates);

    if (dates) {
      const month = dates.getMonth() + 1;
      const year = dates.getFullYear();

      dispatch(getDocumentSentDate({
        month,
        year,
      }));

      setDocumentSentParams({
        ...documentSentParams,
        month,
        year,
      });
    }
  }

  // email
  // TODO delete documentSentParams.email_sent;
  // TODO get input values email and remove
  const [email, setEmail] = React.useState<string | undefined>('');
  const handleChangeEmail = (event: React.ChangeEvent<HandleChangeProps>) => {
    const value: string = event.target.value as string;
    const key: string = event.target.name as string;
    setEmail(value);
    if (value.length > 0) {
      setDocumentSentParams({
        ...documentSentParams,
        [key]: value,
        page: 1,
      });
    } else {
      delete documentSentParams.email_sent;
    }
  };

  // client
  // TODO delete documentSentParams.client;
  // TODO get input values client and remove
  const [client, setClient] = React.useState<string | undefined>('');
  const handleChangeClient = (event: React.ChangeEvent<HandleChangeProps>) => {
    const value: string = event.target.value as string;
    const key: string = event.target.name as string;

    setClient(value);
    if (value.length > 0) {
      setDocumentSentParams({
        ...documentSentParams,
        [key]: value,
        page: 1,
      });
    } else {
      delete documentSentParams.email_sent;
    }
  };

  // pagination

  const { totalItem } = useSelector(getDocumentSent);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setDocumentSentParams({
      ...documentSentParams,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowPerPage);
    setDocumentSentParams({
      ...documentSentParams,
      qtd: rowPerPage,
      page: 1,
    });
    setPage(0);
  };

  // search

  const searchDocumentSent = () => dispatch(getDocumentSentData(documentSentParams));

  React.useEffect(() => {
    searchDocumentSent();
  }, [rowsPerPage, page]);

  const handleClickDocumentSent = () => {
    setPage(0);
    searchDocumentSent();
  };

  const handleClickClearFilter = () => {
    setPage(0);
    setEmail('');
    setClient('');
    setSelectedDate(null);
    setDocumentSentParams(initialState);
    setSelected(initialStateSelected);
  };

  return (
    <Main
      title="Documentos enviados"
      header={
        (
          <div style={{ width: 742 }}>
            <Nav>
              <ul>
                <li>
                  <FormControl>
                    <TextField
                      id="user"
                      label="Cliente"
                      variant="outlined"
                      onChange={handleChangeClient}
                      name="client"
                      value={client}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.selectItem}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      onChange={handleChangeEmail}
                      name="email_sent"
                      value={email}
                    />
                  </FormControl>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl
                    variant="outlined"
                    className={classes.selectItem}
                  >
                    <InputLabel
                      id="document_type"
                      className={classes.inputLabel}
                    >
                      Tipo de documento
                    </InputLabel>
                    <Select
                      labelId="document_type"
                      id="document_type"
                      name="document_type"
                      value={selected.document_type}
                      onChange={handleChange}
                    >
                      <MenuItem value={-1}><em>Todos</em></MenuItem>
                      <MenuItem value={0}>Nenhum tipo de documento</MenuItem>
                      <MenuItem value={1}>SAT</MenuItem>
                      <MenuItem value={2}>NFe</MenuItem>
                      <MenuItem value={3}>NFCe</MenuItem>
                    </Select>
                  </FormControl>
                </li>
                <li>
                  <FormControl
                    variant="outlined"
                    className={classes.selectItem}
                  >
                    <InputLabel
                      id="sent"
                      className={classes.inputLabel}
                    >
                      Enviados
                    </InputLabel>
                    <Select
                      labelId="sent"
                      id="sent"
                      name="sent"
                      value={selected.sent}
                      onChange={handleChange}
                    >
                      <MenuItem value={-1}><em>Todos</em></MenuItem>
                      <MenuItem value={0}>Não</MenuItem>
                      <MenuItem value={1}>Sim</MenuItem>
                    </Select>
                  </FormControl>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl
                    variant="outlined"
                    className={classes.selectItem}
                  >
                    <InputLabel
                      id="errors"
                      className={classes.inputLabel}
                    >
                      Erros
                    </InputLabel>
                    <Select
                      labelId="errors"
                      id="errors"
                      name="errors"
                      value={selected.errors}
                      onChange={handleChange}
                    >
                      <MenuItem value={-1}><em>Todos</em></MenuItem>
                      <MenuItem value={0}>Não</MenuItem>
                      <MenuItem value={1}>Sim</MenuItem>
                    </Select>
                  </FormControl>
                </li>
                <li>
                  <FormControl
                    variant="outlined"
                    className={classes.selectItem}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                      <DatePicker
                        inputVariant="outlined"
                        views={['year', 'month']}
                        label="Ano e mês"
                        minDateMessage="A data não deve ser anterior à data mínima (março 2021)"
                        minDate={new Date(2015, 1, 1)}
                        disableFuture
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </li>
                <li>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px 5px 0',
                  }}
                  >
                    <Button
                      variant="outlined"
                      disableElevation
                      startIcon={<ClearOutlinedIcon />}
                      onClick={handleClickClearFilter}
                      size="small"
                    >
                      Limpar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      startIcon={<SearchIcon />}
                      onClick={handleClickDocumentSent}
                      size="small"
                    >
                      Buscar
                    </Button>
                  </div>
                </li>
              </ul>
            </Nav>
          </div>
        )
      }
      content={(
        <DataGridDocumentSent
          count={totalItem}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    />
  );
}
