import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { filterSyncs, getSyncs } from '../../../store/synchronized/synchronized';

export function DataPicker() {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  const dispatch = useDispatch();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    dispatch(filterSyncs(date));
  };

  const {
    isFiltered,
    total,
  } = useSelector(getSyncs);

  React.useEffect(() => {
    if (!isFiltered) setSelectedDate(new Date());
  }, [total]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        inputVariant="filled"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Filtrar data anterior a"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
