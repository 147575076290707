import { combineReducers } from 'redux';
import all from './softwareAll';
import branch from './softwareBranch';
import modules from './softwareModules';

export default combineReducers({
  all,
  branch,
  modules,
});
