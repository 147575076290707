import DoneIcon from '@material-ui/icons/Done';
import { Chip, Tooltip } from '@material-ui/core';
import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import { useDispatch, useSelector } from 'react-redux';
import { ChipStatusProps } from './types';
import { ModalStatus } from '../Modal';
import { updateOfficeUserStatus } from '../../../store/officeUser';
import { selectorAuth } from '../../../store/auth';

export function ChipStatus(props: ChipStatusProps) {
  const {
    status,
    id,
    name,
  } = props;

  const isStatus = status === 1;
  const title = isStatus ? 'desativar' : 'ativar';

  const [open, setOpen] = React.useState(false);

  const { permissions } = useSelector(selectorAuth);

  const isAllowed = permissions.update_acc_office_users === 1;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const handleChangeStatus = () => {
    dispatch(updateOfficeUserStatus({ id }));
    handleClose();
  };

  return (
    <>
      {isAllowed && (
        <Chip
          variant="outlined"
          size="small"
          icon={isStatus ? <DoneIcon /> : <CloseIcon />}
          label={isStatus ? 'Ativo' : 'Inativo'}
          color={isStatus ? 'primary' : 'secondary'}
          deleteIcon={<Tooltip title={title}><CreateIcon /></Tooltip>}
          onDelete={handleOpen}
        />
      )}
      {!isAllowed && (
        <Chip
          variant="outlined"
          size="small"
          icon={isStatus ? <DoneIcon /> : <CloseIcon />}
          label={isStatus ? 'Ativo' : 'Inativo'}
          color={isStatus ? 'primary' : 'secondary'}
        />
      )}
      <ModalStatus
        open={open}
        handleOpen={handleChangeStatus}
        title="Atenção !"
        description={`Deseja ${title} o usuário`}
        name={name}
        handleClose={handleClose}
      />
    </>
  );
}
