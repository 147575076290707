import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import InfoIcon from '@material-ui/icons/Info';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { Tooltip } from '@material-ui/core';
import clipboard from '../../../services/api/api.clipboard';
import useRowStyles from '../styles/Update.module';
import CollapsibleTable from './CollapsibleTable';

interface RowsProps {
  row: {
    version: string,
    link: string,
    skipable: number,
    changelog: string | null,
    total: number,
    created_at: string,
  }
}

export default function Row(props: RowsProps) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const isChangelogNull = row.changelog === null || row.changelog === '';

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell
          align="center"
          className={`${classes.tableCell} ${classes.spaceAround}`}
        >
          <InfoIcon className={classes.versionIcon} />
          {row.version}
        </TableCell>
        <TableCell
          align="center"
        >
          {new Date(row.created_at).toLocaleDateString('pt-BR')}
        </TableCell>
        <TableCell align="center">
          {row.skipable === 0 && (
            <Tooltip title="SIM">
              <ThumbUpAltIcon color="primary" />
            </Tooltip>
          )}
          {row.skipable === 1 && (
            <Tooltip title="NÃO">
              <ThumbDownIcon color="secondary" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center">
          <Tooltip title={`COPIAR LINK: ${row.link}`}>
            <IconButton
              onClick={() => clipboard({ copy: row.link })}
            >
              <FileCopyIcon color="primary" />
            </IconButton>
          </Tooltip>
          <a href={row.link} download className={classes.download}>
            <Tooltip title="BAIXAR AGORA">
              <IconButton>
                <CloudDownloadIcon color="primary" />
              </IconButton>
            </Tooltip>
          </a>
        </TableCell>
        {isChangelogNull && (
          <TableCell className={`${classes.tableCell} ${classes.start}`}>
            <Tooltip title="Indisponível">
              <FeedbackIcon className={classes.versionIcon} />
            </Tooltip>
          </TableCell>
        )}
        {!isChangelogNull && (
          <TableCell className={`${classes.tableCell} ${classes.start}`} align="center">
            <Tooltip title="mais detalhes">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      {!isChangelogNull && (
        <CollapsibleTable
          changelog={row.changelog as string}
          open={open}
        />
      )}
    </>
  );
}
