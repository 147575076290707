import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoginProps = {
  helper: string,
  isValidEmail: boolean,
  isValidPasswd: boolean,
  credentials: {
    email: string,
    password: string,
  }
}

const initialState: LoginProps = {
  helper: '',
  isValidEmail: false,
  isValidPasswd: false,
  credentials: {
    email: '',
    password: '',
  },
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginEmailInvalid: (login: LoginProps): void => {
      login.helper = 'E-mail inválido';
      login.isValidEmail = false;
    },
    loginEmailValid: (login: LoginProps, action: PayloadAction<string>): void => {
      login.helper = '';
      login.isValidEmail = true;
      login.credentials.email = action.payload;
    },
    loginPasswdInvalid: (login: LoginProps): void => {
      login.isValidPasswd = false;
    },
    loginPasswdValid: (login: LoginProps, action: PayloadAction<string>): void => {
      login.isValidPasswd = true;
      login.credentials.password = action.payload;
    },
    loginClearInputs: (login: LoginProps): void => {
      login.credentials.email = '';
      login.credentials.password = '';
      login.isValidEmail = false;
      login.isValidPasswd = false;
    },
  },
});

export default slice.reducer;

const {
  loginEmailInvalid,
  loginEmailValid,
  loginPasswdInvalid,
  loginPasswdValid,
  loginClearInputs,
} = slice.actions;

const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string) => (dispatch: Function) => {
  const valid = regex.test(email);

  if (valid) dispatch(loginEmailValid(email));
  if (!valid) dispatch(loginEmailInvalid());
};

export const validatePasswd = (passwd: string) => (dispatch: Function) => {
  const valid = passwd.length >= 0 && passwd.length < 5;

  if (!valid) dispatch(loginPasswdValid(passwd));
  if (valid) dispatch(loginPasswdInvalid());
};

export const clearInputs = () => (dispatch: Function) => {
  dispatch(loginClearInputs());
};

/* Selectors */

interface State {
  entities: {
    login: LoginProps
  }
}

export const selectorLogin = createSelector(
  (state: State) => state.entities.login,
  (login: LoginProps) => login,
);
