export interface OfficeUserItem {
  id: number;
  name: string;
  email: string;
  status: number;
  created_at: string;
}

export interface OfficeUserList {
  response_data: OfficeUserItem[];
}

export interface OfficeUserParams {
  status: 1 | 0;
  email?: string;
}

export interface OfficeUser {
  officeUserList: OfficeUserItem[];
  officeUserLoading: boolean;
  status: 1 | 0;
}

export interface OfficeSelectorState {
  entities: {
    officeUsers: OfficeUser,
  }
}

export interface UpdateOfficeUserStatus {
  id: number;
}

// eslint-disable-next-line no-shadow
export enum MessageType {
  dontHavePermission= 'user_dont_have_permission_to_this_operation',
  notFound= '',
}
