import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ApiCallBegan, apiCallBegan } from './api';
import toastMessage from '../services/toastMessage.services';
import history from '../services/history.services';

export interface Permissions {
  admin: number,
  owner: number,
  register_client: number;
  update_client: number;
  register_line_of_work: number;
  register_software: number;
  register_update: number;
  register_accounting_office: number;
  view_logs: number;
  view_branchs: number;
  view_errors: number;
  view_dashboard: number;
  view_analytics: number;
  view_client_information: number;
  register_company: number;
  register_city: number;
  register_user: number;
  view_detach_xml: number;
  view_client_sync: number;
  view_client_version: number;
  view_client_errors: number;
  view_none_doc_issued: number;
  view_software_updates: number;
  view_client_list: number;
  view_acc_office_users: number;
  update_acc_office_users: number;
  view_software_module: number;
  register_software_module: number;
  view_client_modules: number;
  update_client_modules: number;
  view_client_computers: number;
  update_client_computers: number;
  view_sent_documents: number;
  view_users: number,
  update_users: number,
}

interface Token {
  response_data: {
    access_token: string;
  };
}

interface Authentication {
  loggingIn: boolean;
  loggedIn: boolean;
  loading: boolean;
  token: string | null;
  path: string;
  permissions: Permissions;
}

const initialState = {
  loggingIn: false,
  loggedIn: false,
  loading: false,
  token: null,
  path: '',
  permissions: {
    admin: 0,
    owner: 0,
    register_client: 0,
    update_client: 0,
    register_line_of_work: 0,
    register_software: 0,
    register_update: 0,
    register_accounting_office: 0,
    view_logs: 0,
    view_branchs: 0,
    view_errors: 0,
    view_dashboard: 0,
    view_analytics: 0,
    view_client_information: 0,
    register_company: 0,
    register_city: 0,
    register_user: 0,
    view_detach_xml: 0,
    view_client_sync: 0,
    view_client_version: 0,
    view_client_errors: 0,
    view_none_doc_issued: 0,
    view_software_updates: 0,
    view_client_list: 0,
    view_acc_office_users: 0,
    update_acc_office_users: 0,
    view_software_module: 0,
    register_software_module: 0,
    view_client_modules: 0,
    update_client_modules: 0,
    view_client_computers: 0,
    update_client_computers: 0,
    view_sent_documents: 0,
    view_users: 0,
    update_users: 0,
  },
};

export const hello = 'hello';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authRequested: (authentication: Draft<Authentication>): void => {
      authentication.loggingIn = true;
      authentication.loading = true;
    },
    authRequestFailed: (
      authentication: Draft<Authentication>,
      action: PayloadAction<any>,
    ): void => {
      authentication.loading = false;
      authentication.loggingIn = false;

      const message = action.payload;
      toastMessage({
        message,
        types: 'ERROR',
      });
    },
    authSuccess: (
      authentication: Draft<Authentication>,
      action: PayloadAction<Token>,
    ): void => {
      authentication.loggedIn = true;
      authentication.loading = false;

      const { access_token } = action.payload.response_data;
      authentication.token = access_token;

      /*
       * save data session storage
       */
      sessionStorage.setItem(hello, JSON.stringify(access_token));
    },
    authPermissions: (
      authentication: Draft<Authentication>,
      action: PayloadAction<Permissions[]>,
    ): void => {
      [authentication.permissions] = action.payload;
    },
    authLogout: (authentication: Draft<Authentication>): void => {
      authentication.loggingIn = false;
      authentication.loggedIn = false;
      sessionStorage.clear();
      history.push('/login');
    },
  },
});

const {
  authRequested,
  authRequestFailed,
  authSuccess,
  authLogout,
  authPermissions,
} = slice.actions;

export default slice.reducer;

const url = 'auth/login';
const path = '/';

export const authentication = () => (
  dispatch: Function,
  getState: Function,
) => {
  const { credentials } = getState().entities.login;

  const request: ApiCallBegan = {
    url,
    header: { 'Content-Type': 'application/json' },
    method: 'post',
    data: credentials,
    onStart: authRequested.type,
    onSuccess: authSuccess.type,
    onError: authRequestFailed.type,
    redirect: path,
  };

  // request api auth
  dispatch(apiCallBegan(request));
};

export const logoutSession = () => (dispatch: Function) => {
  dispatch(authLogout());
};

export const permission = () => (dispatch: Function) => {
  const data = sessionStorage.getItem(hello);

  if (data) {
    // split string data
    const base64Payload = data.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    const { permissions } = JSON.parse(payload.toString());

    dispatch(authPermissions(permissions));
  }
};

/* Selectors */

type SelectorsProps = {
  entities: {
    auth: Authentication;
  };
};

export const selectorAuth = createSelector(
  (state: SelectorsProps) => state.entities.auth,
  (auth: Authentication) => auth,
);
