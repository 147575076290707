import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterClear, getSyncs } from '../../../store/synchronized/synchronized';

export function ClearButton() {
  // get values sync redux
  const { isFiltered } = useSelector(getSyncs);
  const dispatch = useDispatch();
  // clear filtered list
  const clearFilter = () => dispatch(filterClear());

  return (
    <>
      {isFiltered && (
        <Button
          style={{ height: '32px' }}
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={clearFilter}
          startIcon={<DeleteIcon />}
        >
          Limpar
        </Button>
      )}
    </>
  );
}
