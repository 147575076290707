import {
  createSelector,
  createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import toastMessage from '../../services/toastMessage.services';
import { apiCallBegan, ApiCallBegan } from '../api';
import { hello } from '../auth';
import {
  MessageType,
  OfficeSelectorState,
  OfficeUser,
  OfficeUserList,
  OfficeUserParams,
  UpdateOfficeUserStatus,
  OfficeUserItem,
} from './types';

const initialState: OfficeUser = {
  officeUserList: [],
  officeUserLoading: false,
  status: 1,
};

const slice = createSlice({
  name: 'api/office/users',
  initialState,
  reducers: {
    officeRequest: (office: Draft<OfficeUser>): void => {
      office.officeUserLoading = true;
    },
    officeFailed: (office: Draft<OfficeUser>, action: PayloadAction<string>): void => {
      office.officeUserLoading = false;

      let message: string = action.payload;

      if (message === MessageType.notFound) message = 'Não encontrado';
      if (message === MessageType.dontHavePermission) message = 'Você não tem permissão para esta operação';

      toastMessage({
        types: 'INFO',
        message,
      });
    },
    officeReceived: (office: Draft<OfficeUser>, action: PayloadAction<OfficeUserList>): void => {
      office.officeUserList = action.payload.response_data;
      office.officeUserLoading = false;
    },
    officeStatus: (office: Draft<OfficeUser>, action: PayloadAction<1 | 0>): void => {
      office.status = action.payload;
    },
    officeUserStatus: (office: Draft<OfficeUser>, action: PayloadAction<any>): void => {
      office.officeUserLoading = false;

      const { message } = action.payload;

      toastMessage({
        types: 'INFO',
        message,
      });
    },
    officeUserUpdateList: (office: Draft<OfficeUser>, action: PayloadAction<OfficeUserItem[]>) => {
      office.officeUserList = action.payload;
    },
  },
});

export default slice.reducer;

const {
  officeFailed,
  officeReceived,
  officeRequest,
  officeStatus,
  officeUserStatus,
  officeUserUpdateList,
} = slice.actions;

const url = 'accountingoffice/users';

export function getOfficeUser(params: OfficeUserParams) {
  return (dispatch: Dispatch): void => {
    const token = JSON.parse(<string>sessionStorage.getItem(hello));
    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      url,
      header,
      params,
      onStart: officeRequest.type,
      onError: officeFailed.type,
      onSuccess: officeReceived.type,
    };
    dispatch(apiCallBegan(request));
  };
}

export function updateOfficeUserStatus(params: UpdateOfficeUserStatus) {
  return (dispatch: Dispatch, getState: Function): void => {
    const token = JSON.parse(<string>sessionStorage.getItem(hello));
    const header = { Authorization: `Bearer ${token}` };

    // taking the current state of the list and filtering to remove the item to be updated
    const { officeUserList } = getState().entities.officeUsers;

    // get permission
    const { permissions } = getState().entities.auth;

    const request: ApiCallBegan = {
      url: `${url}/toggleStatus`,
      header,
      method: 'PUT',
      params,
      onStart: officeRequest.type,
      onError: officeFailed.type,
      onSuccess: officeUserStatus.type,
    };
    dispatch(apiCallBegan(request));
    // dispatch new list
    if (permissions.update_acc_office_users === 1) {
      const newOfficeUserList = officeUserList.filter(
        (officeUser: OfficeUserItem) => officeUser.id !== params.id,
      );

      dispatch(officeUserUpdateList(newOfficeUserList));
    }
  };
}

export function setOfficeUserStatus(status: 0 | 1) {
  return (dispatch: Dispatch): void => {
    dispatch(officeStatus(status));
  };
}

export const getOfficeUsers = createSelector(
  (state: OfficeSelectorState) => state.entities.officeUsers,
  (officeUsers: OfficeUser) => officeUsers,
);
