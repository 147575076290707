import * as React from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import { LinearProgress, Tooltip, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { Row } from './styles';
import { getOfficeUsers } from '../../../store/officeUser';
import CopyButton from '../../../components/CopyButton';
import LoadingDataGrid from '../../../components/LoadingDataGrid';
import { ChipStatus } from '../ChipStatus';
import TypographyDate from '../../../components/TypographyDate';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nome',
    width: 150,
    renderCell: (params: GridCellParams) => {
      const description: string = params.value as string;
      return (
        <Tooltip title={description}>
          <Typography
            component="p"
            style={{ textTransform: 'uppercase' }}
          >
            {description}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 350,
    renderCell: (params: GridCellParams) => {
      const email: string = params.value as string;
      return (
        <Row>
          <CopyButton copy={email} />
          <Typography
            style={{ marginLeft: 10 }}
            component="p"
          >
            {email}
          </Typography>
        </Row>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params: GridCellParams) => {
      const id: number = params.row.id as number;
      const name: string = params.row.name as string;
      const status = params.value as 0 | 1;
      return (
        <ChipStatus
          status={status}
          id={id}
          name={name}
        />
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Registrado em',
    width: 200,
    renderCell: (params: GridCellParams) => {
      const date = params.value as string;
      return (
        <Row>
          <TypographyDate date={date} />
          <TodayIcon
            color="primary"
            style={{ marginLeft: 10 }}
          />
        </Row>

      );
    },
  },
];

export function DataGridView() {
  const {
    officeUserLoading,
    officeUserList,
  } = useSelector(getOfficeUsers);

  // component loading
  const Loading = {
    LoadingOverlay: LoadingDataGrid,
  };

  return (
    <>
      {officeUserLoading
        ? <LinearProgress />
        : (
          <DataGrid
            components={Loading}
            rows={officeUserList}
            columns={columns}
            pageSize={10}
            loading={officeUserLoading}
            disableColumnMenu
            disableDensitySelector
            disableSelectionOnClick
            autoHeight
          />
        )}
    </>
  );
}
