import {
  createSelector,
  createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { ApiCallBegan, apiCallBegan } from '../api';
import { hello } from '../auth';
import { DocumentSentParams } from '../../pages/DocumentSent/types';
import toastMessage from '../../services/toastMessage.services';
import { DocumentSent, DocumentSentDate, DocumentSentSelectorState } from './types';

const initialState = {
  documentSentList: [],
  totalItem: 0,
  loadingDocumentSent: false,
  month: 3,
  year: 2021,
};

const slice = createSlice({
  name: 'api/document/sent',
  initialState,
  reducers: {
    documentSentRequest: (document: Draft<DocumentSent>): void => {
      document.loadingDocumentSent = true;
    },
    documentSentFailure: (document: Draft<DocumentSent>, action: PayloadAction<any>): void => {
      document.loadingDocumentSent = false;
      document.documentSentList = [];
      const message = action.payload;
      toastMessage({
        types: 'INFO',
        message,
      });
    },
    documentSentReceived: (document: Draft<DocumentSent>, action: PayloadAction<any>): void => {
      document.loadingDocumentSent = false;
      document.documentSentList = action.payload.response_data.data;
      document.totalItem = action.payload.response_data.total;
    },
    documentSentDate: (document: Draft<DocumentSent>, action: PayloadAction<DocumentSentDate>) => {
      document.year = action.payload.year;
      document.month = action.payload.month;
    },
    documentResendFailure: (document: Draft<DocumentSent>): void => {
      document.loadingDocumentSent = false;
      const message = 'Erro ao reenviar documento!';
      toastMessage({
        types: 'INFO',
        message,
      });
    },
    documentResendSuccess: (document: Draft<DocumentSent>): void => {
      document.loadingDocumentSent = false;
      const message = 'Reenvio dos documentos com sucesso!';
      toastMessage({
        types: 'INFO',
        message,
      });
    },
    documentDisableIssuesFail: (document: Draft<DocumentSent>, action: PayloadAction<any>) => {
      document.loadingDocumentSent = false;

      if (action.payload === 'user_dont_have_permission_to_this_operation') {
        toastMessage({
          types: 'INFO',
          message: 'Você não tem permissão para esta operação!',
        });
      } else {
        toastMessage({
          types: 'INFO',
          message: 'A remoção do controle dos documentos ficais falhou!',
        });
      }
    },
    documentDisableIssuesSuccess: (document: Draft<DocumentSent>): void => {
      document.loadingDocumentSent = false;
      const message = 'Remoção do controle dos documentos com sucesso!';
      toastMessage({
        types: 'INFO',
        message,
      });
    },
  },
});

export default slice.reducer;

const {
  documentSentFailure,
  documentSentRequest,
  documentSentReceived,
  documentSentDate,
  documentResendFailure,
  documentResendSuccess,
  documentDisableIssuesFail,
  documentDisableIssuesSuccess,
} = slice.actions;

export const getDocumentSentData = (params: DocumentSentParams) => (dispatch: Dispatch): void => {
  const token = JSON.parse(<string>sessionStorage.getItem(hello));
  const header = { Authorization: `Bearer ${token}` };

  const request: ApiCallBegan = {
    url: 'fiscal/showDocumentSent',
    header,
    params,
    onStart: documentSentRequest.type,
    onError: documentSentFailure.type,
    onSuccess: documentSentReceived.type,
  };
  dispatch(apiCallBegan(request));
};

export interface ResentDocument {
  year: number;
  month: number;
  client_id: number;
}

export const resentDocument = (params: ResentDocument) => (dispatch: Dispatch): void => {
  const token = JSON.parse(<string>sessionStorage.getItem(hello));
  const header = { Authorization: `Bearer ${token}` };

  const request: ApiCallBegan = {
    url: 'fiscal/sendDocumentAgain',
    header,
    params,
    onStart: documentSentRequest.type,
    onError: documentResendFailure.type,
    onSuccess: documentResendSuccess.type,
  };
  dispatch(apiCallBegan(request));
};

export function disableIssuesDocument(params: {id: number}) {
  return (dispatch: Dispatch): void => {
    const token = JSON.parse(<string>sessionStorage.getItem(hello));
    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      url: 'clients/disableIssuesDocument',
      header,
      method: 'PUT',
      params,
      onStart: documentSentRequest.type,
      onError: documentDisableIssuesFail.type,
      onSuccess: documentDisableIssuesSuccess.type,
    };
    dispatch(apiCallBegan(request));
  };
}

export function getDocumentSentDate(date: DocumentSentDate) {
  return (dispatch: Dispatch): void => {
    dispatch(documentSentDate(date));
  };
}

export const getDocumentSent = createSelector(
  (state: DocumentSentSelectorState) => state.entities.documentSent,
  (documentSent: DocumentSent) => documentSent,
);
