import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authentication, selectorAuth } from '../../../store/auth';
import { selectorLogin } from '../../../store/login';
import { LoginProgressIndicator } from '../ProgressIndicator';
import { Submit } from './styles';
import { HandleSubmitProps } from './types';

export function LoginButton() {
  const dispatch = useDispatch();

  // get values selectors
  const {
    isValidEmail,
    isValidPasswd,
  } = useSelector(selectorLogin);
  const { loading } = useSelector(selectorAuth);

  // submit credentials and auth
  function handleSubmit(event: HandleSubmitProps) {
    event.preventDefault();

    dispatch(authentication());
  }

  return (
    <>
      {loading && <LoginProgressIndicator />}
      {!loading && (
        <Submit
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!(isValidEmail && isValidPasswd)}
        >
          ENTRAR
        </Submit>
      )}
    </>
  );
}
