import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Toolbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { permission, selectorAuth } from '../store/auth';
import PermissionPages from '../components/PermissionPage';
import PageError from './PageError';
import SatReport from './SatReport';
import Version from './Version';
import { OfficeUser } from './OfficeUser';
import { MenuItemPath } from '../components/Menu/MenuItem';
import { Synchronized } from './Synchronized';
import { SynchronizedDetails } from './SynchronizedDetails';
import { DocumentSent } from './DocumentSent';
import Menu from '../components/Menu';
import { ErrorsPerSystem } from './ErrorsPerSystem';
import Customers from './Customers';
import Update from './Update';
import { Main } from '../components/Main';
import { Modules } from './Modules';
import { CustomerModules } from './CustomerModules';
import { CustomerTerminals } from './CustomerTerminals';
import Users from './Users';
import UserDetails from './UserDetails';

const useStylesRoot = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 3,
    padding: theme.spacing(3),
  },
}));

const Root = () => {
  const classes = useStylesRoot();

  const dispatch = useDispatch();
  dispatch(permission());

  const { permissions } = useSelector(selectorAuth);

  return (
    <div className={classes.root}>
      <header>
        <Menu />
      </header>
      <main className={classes.content}>
        <Toolbar />
        <Switch>
          <PermissionPages
            exact
            path={MenuItemPath.sync}
            permission={permissions.view_client_sync}
          >
            <Synchronized />
          </PermissionPages>
          <PermissionPages
            exact
            path={MenuItemPath.syncDate}
            permission={permissions.view_client_sync}
          >
            <SynchronizedDetails />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.versions}
            permission={permissions.view_client_version}
          >
            <Version />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.errors}
            permission={permissions.view_client_errors}
          >
            <ErrorsPerSystem />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.report}
            permission={permissions.view_none_doc_issued}
          >
            <SatReport />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.update}
            permission={permissions.view_software_updates}
          >
            <Update />
          </PermissionPages>
          <PermissionPages
            exact
            path={MenuItemPath.customer}
            permission={permissions.view_client_list}
          >
            <Customers />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.customerModules}
            permission={permissions.view_client_modules}
          >
            <CustomerModules />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.customerTerminals}
            permission={permissions.view_client_computers}
          >
            <CustomerTerminals />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.officeuser}
            permission={permissions.view_acc_office_users}
          >
            <OfficeUser />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.users}
            permission={permissions.view_users}
            exact
          >
            <Users />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.userDetails}
            permission={permissions.view_users}
          >
            <UserDetails />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.document}
            permission={permissions.view_sent_documents}
          >
            <DocumentSent />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.modules}
            permission={permissions.view_software_module}
          >
            <Modules />
          </PermissionPages>
          <PermissionPages
            path={MenuItemPath.test}
            permission={permissions.view_client_list}
          >
            <Main
              title="test main"
              header={<p>teste header</p>}
              content={<p>teste content</p>}
            />
          </PermissionPages>
          <Route path="/access-denied">
            <PageError error={403} />
          </Route>
          <Route path="*">
            <PageError />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default Root;
