import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { MenuItemPath } from '../Menu/MenuItem';

type PropsPrivateRoute = {
  path: MenuItemPath;
  children: React.ReactNode;
  permission: number | string;
  exact?: boolean;
};

const PermissionPages = (props: PropsPrivateRoute) => {
  const { children, permission } = props;

  return (
    <Route {...props}>

      {permission === 1 && children}
      {!permission && <Redirect to={{ pathname: '/access-denied' }} />}
    </Route>
  );
};

PermissionPages.defaultProps = {
  exact: false,
};
export default PermissionPages;
