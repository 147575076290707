import { FormControlLabelPropsTest } from './index';

export const statusUserFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Ativo',
}, {
  value: 0,
  label: 'Inativo',
}, {
  value: -1,
  label: 'Todos',
}];

export const orderByUserFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Crescente',
}, {
  value: 2,
  label: 'Decrescente',
}, {
  value: -1,
  label: 'Qualquer',
}];
