import React, { ReactNode } from 'react';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  Container, Header, HeaderContent, Content,
} from './styles';

interface MainProps {
  title: string,
  header: ReactNode,
  content: ReactNode,
}

export function Main(props: MainProps) {
  const {
    title,
    header,
    content,
  } = props;
  return (
    <Container>
      <Header>
        <div className="header-title">
          <Typography
            variant="h3"
            className="header-text"
          >
            {title}
          </Typography>
          <HeaderContent>
            {header}
          </HeaderContent>
        </div>
      </Header>
      <Content>
        <Card
          className="card-content"
        >
          <div>
            {content}
          </div>
        </Card>
      </Content>
    </Container>
  );
}
