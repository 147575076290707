import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Main } from '../../components/Main';
import { selectorAuth } from '../../store/auth';
import {
  fetchCustomer,
  getCustomers,
  updateComputer,
  updateComputersOptions,
} from '../../store/customers';

interface Computer {
  id: number,
  pc_name: string,
  status: number,
  last_login: string,
  last_software: string,
  token: string,
  access_code: string | null,
  obs: string | null,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  fab: {
    margin: 0,
    top: 'auto',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing(4)}px auto ${theme.spacing(4)}px ${theme.spacing(2.5)}px`,

    '& > *': {
      marginBottom: theme.spacing(1),
    },

    '& button': {
      display: 'inline',
    },
  },
  dialogForm: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const CustomerTerminals: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(getCustomers);
  const { permissions } = useSelector(selectorAuth);

  const [computers, setComputers] = useState<Computer[]>([]);
  const [controlComputers, setControlComputers] = useState(
    0,
  );
  const [maxComputers, setMaxComputers] = useState(0);
  const [computerBeingEdited, setComputerBeingEdited] = useState<Computer>({} as Computer);

  function sumActiveComputers() {
    const total = selectedCustomer?.computers.filter((computer) => (
      computer.status === 1
    )).length;

    return total;
  }

  const [totalActiveComputers, setTotalActiveComputers] = useState(sumActiveComputers());

  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showFab, setShowFab] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomer({ id }));
    }
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      setComputers([...selectedCustomer.computers]);
      setControlComputers(selectedCustomer.control_computers);
      setMaxComputers(selectedCustomer.max_computers);
      setTotalActiveComputers(sumActiveComputers());
    }
  }, [selectedCustomer]);

  useEffect(() => {
    setShowSubmitButton(!(controlComputers === selectedCustomer?.control_computers
      && maxComputers === selectedCustomer?.max_computers));
  }, [selectedCustomer, controlComputers, maxComputers]);

  useEffect(() => {
    setShowFab(JSON.stringify(computers) !== JSON.stringify(selectedCustomer?.computers));
  }, [computers]);

  if (!selectedCustomer) {
    return null;
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>, computerId: number) {
    if (selectedCustomer && permissions.update_client_computers === 1) {
      const updatedComputers: Computer[] = computers.map((computer) => {
        if (computer.id === computerId) {
          return {
            ...computer,
            status: event.target.checked ? 1 : 0,
          };
        }
        return computer;
      });

      setComputers([...updatedComputers]);
    }
  }

  function handleClickEdit(computerId: number) {
    const computer = computers.find((item) => item.id === computerId);

    if (computer) {
      setComputerBeingEdited(computer);
    }
  }

  function handleDialogConfirm() {
    dispatch(updateComputer({ id: Number(id), computers: [computerBeingEdited] }));
    setComputerBeingEdited({} as Computer);
  }

  const gridData = computers.map((computer) => (
    {
      id: computer.id,
      status: computer.status,
      name: computer.pc_name,
      token: computer.token,
      lastSoftware: computer.last_software,
      lastLogin: new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(computer.last_login)),
      accessCode: computer.access_code,
      obs: computer.obs,
    }
  ));

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Ativo',
      renderCell: (params: GridCellParams) => (
        <>
          <Checkbox
            checked={params.value === 1}
            name="isActive"
            color="primary"
            onChange={(event) => handleStatusChange(event, params.row.id as number)}
            disabled={permissions.update_client_computers === 0}
          />
        </>
      ),
    },
    {
      field: 'lastLogin',
      headerName: 'Último login',
      width: 200,
    },
    {
      field: 'lastSoftware',
      headerName: 'Último software',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Nome do computador',
      width: 220,
    },
    {
      field: 'token',
      headerName: 'Token',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <>
          {params.value ? params.value : 'Não informado'}
        </>
      ),
    },
    {
      field: 'accessCode',
      headerName: 'Código de acesso',
      width: 200,
      renderCell: (params: GridCellParams) => (
        <>
          {params.value ? params.value : 'Não informado'}
        </>
      ),
    },
    {
      field: 'obs',
      headerName: 'Observações',
      width: 200,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const obs = params.value as string;

        if (!obs) {
          return (
            <>
              Não informado
            </>
          );
        }

        return (
          <Tooltip
            placement="bottom"
            TransitionComponent={Zoom}
            title={obs}
          >
            <p>{obs}</p>
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: ' ',
      renderCell: (params: GridCellParams) => (
        <IconButton aria-label="editar linha" onClick={() => handleClickEdit(params.row.id as number)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (selectedCustomer) {
      dispatch(updateComputersOptions(
        {
          id: selectedCustomer.id,
          max_computers: maxComputers,
          control_computers: controlComputers,
          name: selectedCustomer.name,
          fantasy_name: selectedCustomer.fantasy_name,
          branch: selectedCustomer.branch,
          clearance: selectedCustomer.clearance,
        },
      ));
    }
  }

  function handleConfirm() {
    dispatch(updateComputer({ id: Number(id), computers }));
  }

  return (
    <>
      {permissions.update_client_modules === 1 && showFab
        && <Fab variant="extended" color="primary" onClick={handleConfirm} className={classes.fab}>Salvar alterações</Fab>}
      <Main
        title={selectedCustomer ? selectedCustomer.fantasy_name : ''}
        header={(
          <Box component="form" className={classes.form} onSubmit={handleSubmit}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="checkedA"
                  color="primary"
                  checked={controlComputers === 1}
                  onChange={(event) => setControlComputers(event.target.checked ? 1 : 0)}
                />
              )}
              label="Controlar terminais"
            />

            <TextField
              variant="outlined"
              label="Máximo de terminais"
              type="number"
              inputProps={{ min: totalActiveComputers }}
              value={maxComputers.toString()}
              onChange={(event) => setMaxComputers(Number(event.target.value))}
            />
            {showSubmitButton
              && <Button type="submit" variant="contained" color="primary">Confirmar</Button>}
          </Box>
        )}
        content={(
          <DataGrid
            disableColumnMenu
            disableDensitySelector
            disableSelectionOnClick
            autoHeight
            rows={gridData}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        )}
      />

      <Dialog open={!!computerBeingEdited.id} maxWidth="sm" fullWidth>
        <DialogTitle>
          {computerBeingEdited?.pc_name}
        </DialogTitle>
        <DialogContent>
          <Box component="form" display="flex" flexDirection="column" className={classes.dialogForm}>
            <TextField
              variant="outlined"
              label="Código de acesso"
              inputProps={{ maxLength: 30 }}
              value={computerBeingEdited.access_code ?? ''}
              onChange={(event) => setComputerBeingEdited({
                ...computerBeingEdited,
                access_code: event.target.value,
              })}
            />
            <TextField
              variant="outlined"
              label="Observações"
              inputProps={{ maxLength: 191 }}
              multiline
              value={computerBeingEdited.obs ?? ''}
              onChange={(event) => setComputerBeingEdited({
                ...computerBeingEdited,
                obs: event.target.value,
              })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setComputerBeingEdited({} as Computer)}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={handleDialogConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
