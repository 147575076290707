const digit = /^\d+$/;
const words = /\D/g;
const cnpj = [/^(\d{2})(\d)/, /^(\d{2})\.(\d{3})(\d)/, /\.(\d{3})(\d)/, /(\d{4})(\d)/];

export const cnpjFormat = (input: string | undefined) => {
  if (input) {
    const valid = digit.test(input);

    if (valid) {
      return input.replace(words, '')
        .replace(cnpj[0], '$1.$2')
        .replace(cnpj[1], '$1.$2.$3')
        .replace(cnpj[2], '.$1/$2')
        .replace(cnpj[3], '$1-$2');
    }
  }
  return input;
};
