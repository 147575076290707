import React from 'react';
import { FormGroup, FormGroupProps, withStyles } from '@material-ui/core';

const CustomerFormGroup = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})((props: FormGroupProps) => <FormGroup color="default" {...props} />);

export default CustomerFormGroup;
