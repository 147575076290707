import React, { useState } from 'react';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Box,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { User } from '../../../store/users';

type ConfirmParams = {
  id: number,
  name: string,
  email: string,
  status: number,
}

interface EditDialogProps {
  dialogProps: DialogProps;
  userBeingEdited: User;
  onConfirm: (user: ConfirmParams) => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },

    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
}));

export function EditDialog(
  {
    dialogProps,
    userBeingEdited,
    onConfirm,
    onCancel,
  }: EditDialogProps,
) {
  const classes = useStyles();
  const [name, setName] = useState(userBeingEdited.name);
  const [email, setEmail] = useState(userBeingEdited.email);
  const [status, setStatus] = useState(userBeingEdited.status);

  const handleConfirm = () => {
    onConfirm(
      {
        id: userBeingEdited.id,
        name,
        email,
        status,
      },
    );
  };

  const handleCancel = () => {
    onCancel();
    setName(userBeingEdited.name);
    setEmail(userBeingEdited.email);
    setStatus(userBeingEdited.status);
  };

  return (
    <Dialog {...dialogProps} maxWidth="sm" fullWidth>
      <DialogTitle>Editar</DialogTitle>
      <DialogContent>
        <Box className={classes.flex}>
          <TextField variant="outlined" label="Nome" value={name} onChange={(e) => setName(e.target.value)} />
          <TextField variant="outlined" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <FormControlLabel
            control={<Switch color="primary" checked={status === 1} onChange={(e) => setStatus(e.target.checked ? 1 : 0)} />}
            label="Ativo"
            labelPlacement="top"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}
