import React from 'react';
import { Card, CardProps, withStyles } from '@material-ui/core';

const CustomerCardStyled = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '400px',
    width: '340px',
    border: '1px solid #cfd8dc',
  },
})((props: CardProps) => <Card elevation={0} color="default" {...props} />);

export default CustomerCardStyled;
