import IconButton from '@material-ui/core/IconButton';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getDocumentSent, resentDocument } from '../../../store/documentSent';

interface ResendActionButtonProps {
  client_id: number;
  fantasy_name: string;
}

export function ResendActionButton(props: ResendActionButtonProps) {
  const { client_id, fantasy_name } = props;
  const { year, month } = useSelector(getDocumentSent);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleResend() {
    dispatch(resentDocument({ client_id, year, month }));
    setOpen(false);
  }

  return (
    <div>
      <Tooltip title="REENVIAR">
        <IconButton onClick={handleClickOpen}>
          <ReplayOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {fantasy_name.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você confirma o reenvio dos documentos do mês
            {' '}
            {`${moment(`${month}/${year}`, ['MM/YYYY']).format('MM/YYYY')}`}
            {' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
          <Button
            variant="contained"
            onClick={handleResend}
            color="primary"
          >
            Sim, confirmar
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
