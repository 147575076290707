import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getSyncDetails, getSyncs } from '../../store/synchronized/synchronized';
import LoadingDataGrid from '../../components/LoadingDataGrid';
import { UseParamsTypeDate } from './type';
import BackButton from '../../components/BackButton';
import { SynchronizedDate } from './SynchronizedDate';

const SyncColumnsDetails: GridColDef[] = [
  {
    field: 'fantasy_name',
    headerName: 'Nome Fantasia',
    width: 550,
    renderCell: (params: GridCellParams) => {
      const name = params.value;
      return (
        <Typography
          style={{
            color: '#424242',
            textTransform: 'uppercase',
            fontSize: '14px',
          }}
          variant="subtitle1"
        >
          {name}
        </Typography>
      );
    },
  },
];

export function SynchronizedDetails() {
  /*
   * use param date = get date
   */
  const { date }: UseParamsTypeDate = useParams();

  const dispatch = useDispatch();

  // loading the list
  useEffect(() => {
    dispatch(getSyncDetails(date));
  }, [dispatch]);

  const synchronized = useSelector(getSyncs);
  const {
    listDetails,
    loading,
  } = synchronized;

  return (
    <section>
      <header>
        <BackButton />
        <SynchronizedDate date={date} />
      </header>
      {/* end - show message sync or not sync title */}

      <Divider />

      {/* component table */
      }
      <article style={{
        height: '70vh',
        width: '100%',
      }}
      >
        <DataGrid
          components={{
            LoadingOverlay: LoadingDataGrid,
          }}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          rows={listDetails}
          columns={SyncColumnsDetails}
          rowsPerPageOptions={[25, 50, 100]}
          loading={loading}
        />
      </article>
    </section>
  );
}
