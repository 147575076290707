import Grid from '@material-ui/core/Grid';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import logoSmall from '../../assets/media/logo-wls.png';
import logoLarge from '../../assets/media/logo-wls-horizontal.e332934f.png';

interface CopyrightProps {
  variant?: 'large' | 'small'
}

const Copyright = (props: CopyrightProps) => {
  const { variant } = props;

  return (
    <Grid container spacing={0} justify="center">
      <List>
        <ListItem>
          <ListItemIcon>
            {variant === 'small'
              ? <img src={logoSmall} alt="logo wls sistemas" width={35} height={35} />
              : <img src={logoLarge} alt="logo wls sistemas" width={200} height={50} />}
          </ListItemIcon>
        </ListItem>
      </List>
    </Grid>
  );
};

Copyright.defaultProps = {
  variant: 'large',
};

export default Copyright;
