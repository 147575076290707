import {
  createSelector,
  createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { hello } from './auth';
import { apiCallBegan } from './api';

interface Changelog {
    version: string,
    link: string,
    skipable: number,
    changelog: string,
    total: number,
    created_at: string,
}

interface UpdateChangelog {
    changelog: Changelog[],
    loadingChangelog: boolean,
    total: number,
}

const initialState = {
  changelog: [],
  loadingChangelog: false,
  total: 0,
};

const slice = createSlice({
  name: 'api/update/changelog',
  initialState,
  reducers: {
    changelogRequest: (update: Draft<UpdateChangelog>): void => {
      update.loadingChangelog = true;
    },
    changelogFailed: (update: Draft<UpdateChangelog>): void => {
      update.loadingChangelog = false;
    },
    changelogReceived: (update: Draft<UpdateChangelog>, action: PayloadAction<any>): void => {
      update.loadingChangelog = false;
      const { data, total } = action.payload.response_data;
      update.changelog = data;
      update.total = total;
    },
    changelogClear: (update: Draft<UpdateChangelog>): void => {
      update.changelog = [];
      update.total = 0;
    },
  },
});

export default slice.reducer;

const {
  changelogRequest,
  changelogFailed,
  changelogReceived,
  changelogClear,
} = slice.actions;

export function changelogClearData() {
  return (dispatch: Dispatch): void => {
    dispatch(changelogClear());
  };
}

const url = 'update/changelog';

interface LoadChangeLogParams {
    software_id: number,
    page: number,
    qtd: number,
    branch?: string,
}

export function loadChangelog(params: LoadChangeLogParams) {
  return (dispatch: Dispatch, getState: Function): void => {
    // get token
    let { token }: { token: string } = getState().entities.auth;
    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));
    // api request for get changelog
    const header = { Authorization: `Bearer ${token}` };
    const request = {
      url,
      params,
      header,
      onStart: changelogRequest.type,
      onSuccess: changelogReceived.type,
      onError: changelogFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

interface GetChangeLog {
    entities: {
        updateChangelog: UpdateChangelog,
    },
}

export const getChangelog = createSelector(
  (state: GetChangeLog) => state.entities.updateChangelog,
  (updateChangelog) => updateChangelog,
);
