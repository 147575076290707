import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  GridColDef,
  GridCellParams,
  DataGrid,
} from '@material-ui/data-grid';
import { Main } from '../../components/Main';
import { getSoftwareList, loadSoftwareList } from '../../store/software/softwareAll';
import { selectorAuth } from '../../store/auth';
import { loadSoftwareModules, getSoftwareModules, registerNewModule } from '../../store/software/softwareModules';
import LoadingDataGrid from '../../components/LoadingDataGrid';

interface Software {
  id: number;
  name: string;
}

export const Modules: React.FC = () => {
  const dispatch = useDispatch();

  const { listSoftware } = useSelector(getSoftwareList);
  const { listModules, loadingModules } = useSelector(getSoftwareModules);
  const { permissions } = useSelector(selectorAuth);

  const [selectedSoftware, setSelectedSoftware] = useState('SISLoja');
  const [newModuleDescription, setNewModuleDescription] = useState('');
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  useEffect(() => {
    dispatch(loadSoftwareList());
  }, []);

  useEffect(() => {
    dispatch(loadSoftwareModules({ software: selectedSoftware }));
  }, [selectedSoftware]);

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Data de cadastro',
      width: 200,

      renderCell: (params: GridCellParams) => (
        <>
          {
            new Date(params.value as string).toLocaleDateString(
              'pt-BR',
              { day: '2-digit', month: '2-digit', year: 'numeric' },
            )
          }
        </>
      ),
    },
    {
      field: 'description',
      headerName: 'Nome',
      width: 200,
      flex: 1,
    },
  ];

  function handleSelectChange(event: React.ChangeEvent<{ value: unknown }>) {
    setSelectedSoftware(event.target.value as string);
  }

  function handleOpenDialog() {
    setDialogIsOpen(true);
  }

  function handleCloseDialog() {
    setNewModuleDescription('');
    setDialogIsOpen(false);
  }

  function handleConfirmDialog() {
    if (newModuleDescription) {
      dispatch(registerNewModule(
        {
          software: selectedSoftware,
          description: newModuleDescription,
        },
      ));

      handleCloseDialog();
    }
  }

  return (
    <>
      <Main
        title="Módulos"
        header={(
          <Box>
            <FormControl variant="filled">
              <InputLabel id="software-list">Sistema</InputLabel>
              <Select
                variant="filled"
                labelId="software-list"
                id="software-list-select"
                value={selectedSoftware}
                onChange={handleSelectChange}
              >
                {listSoftware.map((software: Software) => (
                  <MenuItem value={software.name} key={software.id}>{software.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        content={
          (
            <>
              {permissions.register_software_module === 1 && (
                <Box textAlign="right">
                  <Button variant="contained" color="primary" onClick={handleOpenDialog}>Adicionar novo</Button>
                </Box>
              )}
              <DataGrid
                components={{
                  LoadingOverlay: LoadingDataGrid,
                }}
                disableColumnMenu
                disableDensitySelector
                disableSelectionOnClick
                autoHeight
                rows={listModules}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[10, 25, 50, 100]}
                loading={loadingModules}
              />
            </>
          )
        }
      />
      <Container maxWidth="sm">
        <Dialog
          open={dialogIsOpen}
          keepMounted
          fullWidth
        >
          <DialogTitle>
            Novo módulo
          </DialogTitle>
          <DialogContent>
            <form>
              <TextField label="Nome do módulo" variant="filled" value={newModuleDescription} onChange={(e) => setNewModuleDescription(e.target.value)} />
            </form>
          </DialogContent>
          <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmDialog}
            >
              Confirmar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};
