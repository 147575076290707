import { useSelector } from 'react-redux';
import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { getCustomers } from '../../../store/customers';
import useStylesCustomer from '../styles/Customer.module';
import CustomerItem from '../Item';
import CustomerFormGroup from '../Form';

interface Customer {
  id: number,
  created_at: string,
  fantasy_name: string,
  corporate_name: string,
  cnpj: string,
  status: number,
  clearance: number,
  test: number,
  branch: string,
  last_sync: string,
  database_type: number,
  sat_emission_type: number,
  sat_manager: number,
  accounting_office: {
    fantasy_name: string,
  }
}

interface CustomerListProps {
  count: number | undefined,
  page: number | undefined,
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void,
  hidden: boolean | undefined,
}

export default function CustomerList(props: CustomerListProps) {
  const classes = useStylesCustomer();

  const {
    onChange,
    hidden,
    page,
    count,
  } = props;

  const {
    customerList,
    customerLoading,
  } = useSelector(getCustomers);

  return (
    <>
      <CustomerFormGroup>
        {customerLoading && <LinearProgress />}
        {!customerLoading && (
          <ul className={classes.containerList}>
            {customerList.map((customer: Customer) => (
              <li
                key={`${customer.id}-CustomerItem`}
                className={classes.itemList}
              >
                <CustomerItem
                  customer={customer}
                />
              </li>
            ))}
          </ul>
        )}
      </CustomerFormGroup>
      {!customerLoading && (
        <CustomerFormGroup row>
          <Pagination
            count={count}
            page={page}
            onChange={onChange}
            showFirstButton
            showLastButton
            hidden={hidden}
          />
        </CustomerFormGroup>
      )}

    </>
  );
}
