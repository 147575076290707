import { Typography } from '@material-ui/core';
import React from 'react';

interface TypographyDataBaseTypeProps {
  dataBaseType: number | undefined;
}

const TypographyDataBaseType = ({ dataBaseType }: TypographyDataBaseTypeProps) => {
  const databaseType = (type: number | undefined): string => {
    if (type === 1) return 'Access';
    if (type === 2) return 'Firebird';
    if (type === 3) return 'SQL Server';
    if (type === 4) return 'MariaDB';
    return 'não informado';
  };

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
    >
      <strong>Banco de Dados: </strong>
      {!dataBaseType && 'não informado'}
      {dataBaseType && databaseType(dataBaseType)}
    </Typography>
  );
};

export default TypographyDataBaseType;
