import { withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

const styles = (theme: Theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    padding: '18px 36px',
    fontSize: '18px',
  },
});

export const Submit = withStyles(styles)((props: ButtonProps) => <Button {...props} />);
