import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { selectorLogin, validateEmail, validatePasswd } from '../../../store/login';
import { HandleChangeProps } from './types';
import { Container } from './styles';

export function LoginForm() {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  // get values selectors
  const {
    isValidEmail,
    isValidPasswd,
    helper,
  } = useSelector(selectorLogin);

  // INIT - get values inputs

  const dispatch = useDispatch();

  function getEmailValues(e: HandleChangeProps) {
    const { value } = e.target;
    dispatch(validateEmail(value));
  }

  function getPasswdValues(e: HandleChangeProps) {
    const { value } = e.target;
    dispatch(validatePasswd(value));
  }

  // END get values inputs

  return (
    <Container>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        helperText={helper}
        error={!isValidEmail}
        onChange={getEmailValues}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Senha"
        name="password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="current-password"
        error={!isValidPasswd}
        onChange={getPasswdValues}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                color="primary"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
}
