import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core';

import LoadingDataGrid from '../../../components/LoadingDataGrid/LoadingDataGrid';
import columns from './SoftwareColumn';

import error404 from '../../../assets/media/error-404.svg';
import { getErrorPerSystem } from '../../../store/errorsPerSystem';

export default function SoftwareErrorList() {
  const {
    errorList,
    errorListFound,
    loading,
  } = useSelector(getErrorPerSystem);

  return (
    <Grid>
      {errorListFound && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >

          <Grid item xs={3}>
            <img src={error404} width="400" height="400" alt="erro 404" />
          </Grid>

        </Grid>
      )}
      {
        loading
          ? <LinearProgress />
          : (
            <DataGrid
              components={{
                LoadingOverlay: LoadingDataGrid,
              }}
              disableColumnMenu
              disableDensitySelector
              disableSelectionOnClick
              autoHeight
              rows={errorList}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              loading={loading}
            />
          )
      }
    </Grid>
  );
}
