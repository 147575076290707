import {
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { clearVersionsData, loadDataGraphic, loadListVersion } from '../../../store/versions';
import { getSoftwareList, loadSoftwareList } from '../../../store/software/softwareAll';
import ComboBoxAsynchronous from '../../../components/ComboBoxAsynchronous';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    paddingTop: '8px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  softwareList: {
    paddingTop: '15px',
  },
}));

type ValuesAutocomplete = {
  id: number,
  name: string,
}

export default function VersionHeader() {
  const classes = useStyles();

  const [size, setSize] = React.useState('5');
  const [software_id, setSoftware_id] = React.useState<number | null>(null);

  const formatDate = new Date(moment()
    .format('L'));
  const initialState = new Date(formatDate.setMonth(formatDate.getMonth() - 1));

  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(initialState);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadSoftwareList());
  }, []);

  const {
    listSoftware,
    loadingSoftware,
  } = useSelector(getSoftwareList);

  const handleChangeColumns = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSize(event.target.value as string);
  };

  function handleDateInitialChange(date: Date | null) {
    setSelectedFromDate(date);
  }

  function getValuesAutocomplete(getValue: ValuesAutocomplete) {
    if (getValue !== null) {
      const { id } = getValue as ValuesAutocomplete;
      setSoftware_id(id);
    } else {
      setSoftware_id(null);
      dispatch(clearVersionsData());
    }
  }

  function handleSearchVersionData() {
    const toDate = moment(selectedFromDate)
      .format('DD/MM/YYYY');

    if (software_id) {
      dispatch(loadDataGraphic({
        toDate,
        software_id,
        size,
      }));

      dispatch(loadDataGraphic({
        software_id,
        size,
      }));

      dispatch(loadListVersion(software_id));
    }
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 100,
      width: 800,
    }}
    >
      <ComboBoxAsynchronous
        loading={loadingSoftware}
        list={listSoftware}
        onChange={getValuesAutocomplete}
        id="software-list"
      />
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <KeyboardDatePicker
            disableToolbar
            variant="dialog"
            inputVariant="filled"
            format="dd/MM/yyyy"
            margin="normal"
            id="data_anterior"
            label="Data anterior"
            value={selectedFromDate}
            onChange={handleDateInitialChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="items-graphic">Gráfico</InputLabel>
          <Select
            labelId="items-graphic"
            id="items-graphic"
            value={size}
            onChange={handleChangeColumns}
          >
            <MenuItem value={5}>5 colunas</MenuItem>
            <MenuItem value={6}>6 colunas</MenuItem>
            <MenuItem value={7}>7 colunas</MenuItem>
            <MenuItem value={8}>8 colunas</MenuItem>
            <MenuItem value={9}>9 colunas</MenuItem>
            <MenuItem value={10}>10 colunas</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <Button
          onClick={handleSearchVersionData}
          disabled={!software_id}
          variant="contained"
          startIcon={<SearchIcon />}
          disableElevation
          color="primary"
        >
          Buscar
        </Button>
      </div>
    </div>
  );
}
