import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { Typography } from '@material-ui/core';

export default function TableChangelog(props: { description: string }) {
  const { description } = props;

  // replace * to <strong> and :* </strong>
  const newDescription = description
    .replace(/:\*/g, ':</strong>')
    .replace(/\*/g, '<br><strong>');

  // set string in __html
  const text = {
    __html: `${newDescription}`,
  };
  return (
    <>
      <TableCell component="th" scope="row">
        <Typography
          style={{
            display: 'inline-block',
            color: '#424242',
          }}
          variant="subtitle1"
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={text} />
        </Typography>
      </TableCell>
    </>
  );
}
