import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './styles';
import { getOfficeUsers, setOfficeUserStatus } from '../../../store/officeUser';

export default function SelectStatus() {
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as 0 | 1;
    setUserStatus(value);
    dispatch(setOfficeUserStatus(value));
  };

  const { status } = useSelector(getOfficeUsers);

  React.useEffect(() => {
    setUserStatus(status);
  }, [status]);

  return (
    <FormControl>
      <InputLabel id="demo-customized-select-label">Status</InputLabel>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={userStatus}
        onChange={handleChange}
        input={<Styles />}
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <MenuItem value={1}>Ativo</MenuItem>
        <MenuItem value={0}>Inativo</MenuItem>
      </Select>
    </FormControl>
  );
}
