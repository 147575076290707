import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStylesSearch = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(2),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 860,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  comboBox: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  check: {
    marginLeft: theme.spacing(1),
  },
}));

export default useStylesSearch;
