import Grid from '@material-ui/core/Grid';
import { createStyles } from '@material-ui/core';

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { HomeOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import error404 from '../../assets/media/error-404.svg';
import error403 from '../../assets/media/acces-denied.svg';
import error500 from '../../assets/media/error-500.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
}));

/* Component page not found */
const PageError = (props: { error?: number }) => {
  const { error } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className={classes.paper}>
              <Button color="primary">
                <HomeOutlined />
                Página Inicial
              </Button>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.paper}>
            {error === 403 && <img src={error403} className={classes.image} alt="erro 403" />}
            {error === 404 && <img src={error404} className={classes.image} alt="erro 404" />}
            {error === 500 && <img src={error500} className={classes.image} alt="erro 500" />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

PageError.defaultProps = {
  error: 404,
};

export default PageError;
