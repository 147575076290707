import {
  createSlice, Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { ResponseType } from 'axios';
import { ApiCallBegan, apiCallBegan } from './api';
import { hello } from './auth';

type BufferSource = ArrayBufferView | ArrayBuffer;
type BlobPart = BufferSource | Blob | string;

type softwarePros = {
  loading: boolean,
  fileName: string;
}

const initialState = {
  loading: false,
  fileName: '',
};

const slice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    downloadRequested: (download: Draft<softwarePros>): void => {
      download.loading = true;
    },
    downloadRequestFailed: (download: Draft<softwarePros>): void => {
      download.loading = false;
    },
    downloadReceived: (download: Draft<softwarePros>, action: PayloadAction<BlobPart>): void => {
      download.loading = false;
      // get responseData
      const blobParts: BlobPart = action.payload;

      // generate url
      const url = window.URL.createObjectURL(new Blob([blobParts]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', download.fileName);

      document.body.appendChild(link);

      link.click();
    },
    contentDisposition: (download: Draft<softwarePros>, action: PayloadAction<string>): void => {
      download.fileName = action.payload;
    },
  },
});

const {
  downloadRequested,
  downloadRequestFailed,
  downloadReceived,
  contentDisposition,
} = slice.actions;

export default slice.reducer;

// Action Creators

type tokenType = {
  readonly token: string,
}

export function downloadReportErrors(initialDate: Date, endDate: Date, software_id: number) {
  return (dispatch: Function, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const fileName = 'Relatorio_Erros.xlsx';
    dispatch(contentDisposition(fileName));

    const fromDate: string = moment(new Date(initialDate)).format('DD/MM/YYYY');
    const toDate = moment(new Date(endDate)).format('DD/MM/YYYY');

    const request: ApiCallBegan = {
      url: 'errors/getReport',
      responseType: 'blob',
      header: { Authorization: `Bearer ${token}` },
      params: {
        fromDate,
        toDate,
        software_id,
      },
      onStart: downloadRequested.type,
      onSuccess: downloadReceived.type,
      onError: downloadRequestFailed.type,
    };
    dispatch(apiCallBegan(request));
  };
}

export function downloadReportSat(month: number, year: number) {
  return (dispatch: Function, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const fileName = 'RelatorioSatSemDocumentos.xlsx';
    dispatch(contentDisposition(fileName));

    const responseType: ResponseType = 'blob';

    const request: ApiCallBegan = {
      url: 'fiscal/reports/sat/get/nonedocissued',
      responseType,
      header: { Authorization: `Bearer ${token}` },
      params: {
        month,
        year,
      },
      onStart: downloadRequested.type,
      onSuccess: downloadReceived.type,
      onError: downloadRequestFailed.type,
    };
    dispatch(apiCallBegan(request));
  };
}
