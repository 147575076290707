import React from 'react';
import ErrorsPerSystemHeader from './Header';
import SoftwareErrorList from './DataGridErrors/SoftwareErrorList';
import { Main } from '../../components/Main';

export function ErrorsPerSystem() {
  return (
    <Main
      title="Erro por sistema"
      header={<ErrorsPerSystemHeader />}
      content={<SoftwareErrorList />}
    />
  );
}
