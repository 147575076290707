import { toast } from 'react-toastify';

type ToastId = {
  current: number | string;
}
const toastId: ToastId = { current: 0 };

interface ToastMessageProps {
  types: 'INFO' | 'ERROR' | 'SUCCESS' | 'WARNING',
  message: string
}

const toastMessage = (props: ToastMessageProps) => {
  const { types, message } = props;
  if (!toast.isActive(toastId.current)) {
    switch (types) {
      case 'INFO':
        toastId.current = toast.info(`${message}`);
        break;
      case 'SUCCESS':
        toastId.current = toast.success(`${message}`);
        break;
      case 'WARNING':
        toastId.current = toast.warn(`${message}`);
        break;
      case 'ERROR':
        toastId.current = toast.error(`${message}`);
        break;
      default:
        toastId.current = toast(`${message}`);
    }
  }
};

export default toastMessage;
