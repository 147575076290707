import styled from 'styled-components';

export const Container = styled.div`  
  width: 100%;
`;

export const Content = styled.div`
  margin: 20px;
  height: 80vh;  
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
