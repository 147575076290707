import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

interface TypographyDateProps {
  date: string;
}

export default function TypographyDate({ date }: TypographyDateProps) {
  const formatDate = moment(new Date(date as string))
    .format('DD/MM/yyyy');

  return (
    <Typography>
      {formatDate}
    </Typography>
  );
}
