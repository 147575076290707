import IconButton from '@material-ui/core/IconButton';
import { RemoveCircleOutline } from '@material-ui/icons';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { disableIssuesDocument } from '../../../store/documentSent';

interface DisableIssuesDocumentProps {
  client_id: number;
  fantasy_name: string;
}

export function DisableIssuesDocument({ client_id, fantasy_name }:DisableIssuesDocumentProps) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleSubmit() {
    dispatch(disableIssuesDocument({ id: client_id }));
    setOpen(false);
  }
  return (
    <div>
      <Tooltip title="REMOVER CONTROLE">
        <IconButton onClick={handleClickOpen}>
          <RemoveCircleOutline color="secondary" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {fantasy_name.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você confirma a remoção do controle dos documentos fiscais?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
          >
            Sim, confirmar
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
