import React from 'react';
import VersionHeader from './VersionHeader';
import VersionGraphics from './Graphic/VersionGraphics';
import VersionDataGrid from './VersionDataGrid/VersionDataGrid';
import { Main } from '../../components/Main';

export default function Version() {
  return (
    <Main
      title="Versões"
      header={<VersionHeader />}
      content={(
        <>
          <VersionGraphics />
          <VersionDataGrid />
        </>
      )}
    />

  );
}
