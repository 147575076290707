import React from 'react';
import { DataPicker } from '../DataPicker';
import { ClearButton } from '../ClearButton';
import { SynchronizedTotal } from '../SynchronizedTotal/SynchronizedTotal';

export function Header() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 10,
      }}
      >
        <DataPicker />
        <ClearButton />
      </div>
      <SynchronizedTotal />
    </div>
  );
}
