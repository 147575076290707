import React from 'react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FlexColumn from '../../../components/Flex/FlexColumn';
import FormControlStyled from '../../../components/FormControlStyled';
import FormLabelStyled from '../../../components/FormLabelStyled';

type UserParamsKeys = 'status' | 'order_by';

type Status = 'Ativo' | 'Inativo' | 'Todos';
type OrderBy = 'Crescente' | 'Decrescente' | 'Qualquer';

type FormControlLabelProps = Status | OrderBy;

export interface FormControlLabelPropsTest {
  value: number,
  label: FormControlLabelProps,
}

interface UserFilterProps {
  label: string;
  onChange: (option: number, key: UserParamsKeys) => void;
  nameLabel: UserParamsKeys,
  formControl: FormControlLabelPropsTest[];
  value: number;
}

export default function UserFilter(props: UserFilterProps) {
  const {
    label,
    onChange,
    nameLabel,
    formControl,
    value,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get values prop name and value
    const key: UserParamsKeys = (event.target as HTMLInputElement).name as UserParamsKeys;
    const option = +(event.target as HTMLInputElement).value as number;
    // update customerParams
    onChange(option, key);
  };

  return (
    <FlexColumn>
      <FormControlStyled>
        <FormLabelStyled>{label}</FormLabelStyled>
        <RadioGroup
          aria-label={label}
          name={label}
          value={value}
          onChange={handleChange}
        >
          {formControl.map((data, index: number) => (
            <FormControlLabel
              key={index as number}
              value={data.value}
              control={<Radio color="primary" />}
              label={data.label}
              name={nameLabel}
            />
          ))}
        </RadioGroup>
      </FormControlStyled>
    </FlexColumn>
  );
}
