import React from 'react';
import { Typography } from '@material-ui/core';
import env from '../../env';
import Container from './styles';

export default function AppVersion() {
  return (
    <Container>
      <Typography
        variant="subtitle2"
      >
        {env.REACT_APP_VERSION}
      </Typography>
    </Container>
  );
}
