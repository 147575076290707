import React from 'react';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import UpdateIcon from '@material-ui/icons/Update';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useSelector } from 'react-redux';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { selectorAuth } from '../../store/auth';

// eslint-disable-next-line no-shadow
export enum MenuItemPath {
  versions = '/versions',
  sync = '/sync',
  syncDate = '/sync/:date',
  errors = '/errors',
  report = '/report',
  update = '/update',
  customer = '/customers',
  customerModules = '/customers/modules/:id',
  customerTerminals = '/customers/terminals/:id',
  officeuser = '/officeuser',
  document = '/document/sent',
  modules = '/modules',
  test = '/test',
  users = '/users',
  userDetails = '/users/:id',
}

interface MenuItem {
  icon: React.ReactElement,
  text: string,
  path: MenuItemPath,
  permission: string | number,
}

const MenuItems = (): MenuItem[] => {
  const { permissions } = useSelector(selectorAuth);

  return [
    {
      icon: <UpdateIcon color="primary" />,
      text: 'Atualizações',
      path: MenuItemPath.update,
      permission: permissions.view_software_updates,
    },
    {
      icon: <PeopleOutlineIcon color="primary" />,
      text: 'Clientes',
      path: MenuItemPath.customer,
      permission: permissions.view_client_list,
    },
    {
      icon: <MarkunreadMailboxOutlinedIcon color="primary" />,
      text: 'Documentos enviados',
      path: MenuItemPath.document,
      permission: permissions.view_sent_documents,
    },
    {
      icon: <ErrorOutlineOutlinedIcon color="primary" />,
      text: 'Erro por sistema',
      path: MenuItemPath.errors,
      permission: permissions.view_client_errors,
    },
    {
      icon: <ExtensionOutlinedIcon color="primary" />,
      text: 'Módulos',
      path: MenuItemPath.modules,
      permission: permissions.view_software_module,
    },
    {
      icon: <CancelPresentationIcon color="secondary" />,
      text: 'Page test',
      path: MenuItemPath.test,
      permission: 0,
    },
    {
      icon: <LocalPrintshopOutlinedIcon color="primary" />,
      text: 'Relatório SAT',
      path: MenuItemPath.report,
      permission: permissions.view_none_doc_issued,
    },
    {
      icon: <SyncOutlinedIcon color="primary" />,
      text: 'Sincronizados',
      path: MenuItemPath.sync,
      permission: permissions.view_client_sync,
    },
    {
      icon: <PermIdentityOutlinedIcon color="primary" />,
      text: 'Usuários',
      path: MenuItemPath.users,
      permission: permissions.view_users,
    },
    {
      icon: <GroupOutlinedIcon color="primary" />,
      text: 'Usuários escritórios',
      path: MenuItemPath.officeuser,
      permission: permissions.view_acc_office_users,
    },
    {
      icon: <HistoryOutlinedIcon color="primary" />,
      text: 'Versões',
      path: MenuItemPath.versions,
      permission: permissions.view_client_version,
    },
  ];
};

export default MenuItems;
