import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStylesCustomer = makeStyles((theme: Theme) => createStyles({
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  containerList: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0',
  },
  itemList: {
    float: 'left',
    padding: '5px',
    margin: '10px',
    listStyle: 'none',
    lineHeight: '150px',
  },
  chip: {
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  released: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  delay: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.contrastText,
  },
  block: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default useStylesCustomer;
