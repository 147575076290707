import { FormControlLabelPropsTest } from './index';

export const defaultCustomerFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Sim',
}, {
  value: 0,
  label: 'Não',
}, {
  value: -1,
  label: 'Todos',
}];

export const statusCustomerFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Ativo',
}, {
  value: 0,
  label: 'Inativo',
}, {
  value: -1,
  label: 'Todos',
}];

export const satEmissionCustomerTypeFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Tecnospeed',
}, {
  value: 2,
  label: 'ACBrLib',
}, {
  value: -1,
  label: 'Todos',
}];

export const dataBaseTypeCustomerFormControl: FormControlLabelPropsTest[] = [{
  value: 1,
  label: 'Access',
}, {
  value: 2,
  label: 'Firebird',
}, {
  value: 3,
  label: 'SQL Server',
}, {
  value: 4,
  label: 'MariaDB',
}, {
  value: -1,
  label: 'Todos',
}];

export const clearanceCustomerFormControl: FormControlLabelPropsTest[] = [{
  value: 0,
  label: 'Bloqueado',
}, {
  value: 1,
  label: 'Liberado',
}, {
  value: 2,
  label: 'Alerta de pagamento',
}, {
  value: -1,
  label: 'Todos',
}];
