import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import ComboBoxAsynchronous from '../../../components/ComboBoxAsynchronous';
import { getSoftwareList, loadSoftwareList } from '../../../store/software/softwareAll';
import {
  branchClearData,
  getSoftwareBranch,
  loadSoftwareBranch,
} from '../../../store/software/softwareBranch';
import { changelogClearData, getChangelog, loadChangelog } from '../../../store/updateChangelog';
import { UpdateTable } from '../UpdateDataGrid/UpdateTable';
import { IUpdate } from './types';
import { Main } from '../../../components/Main';

const initialState = {
  branch: 'stable',
  page: 1,
  qtd: 10,
  software_id: 1,
};

export default function UpdateChangelog() {
  const sislojaId = 1;

  const dispatch = useDispatch();

  const [params, setParams] = React.useState<IUpdate>(initialState);

  const [isInitialRender, setIsInitialRender] = React.useState(true);

  const getChangeLog = () => dispatch(loadChangelog(params));

  // data grid
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setParams({
      ...params,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const qtdRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(qtdRowsPerPage);
    setParams({
      ...params,
      qtd: qtdRowsPerPage,
      page: 1,
    });
    setPage(0);
  };
  // end data grid

  React.useEffect(() => {
    dispatch(loadSoftwareList());
    dispatch(loadSoftwareBranch({ software_id: sislojaId }));
  }, []);

  const {
    listSoftware,
    loadingSoftware,
  } = useSelector(getSoftwareList);
  const {
    listBranch,
    loadingBranch,
  } = useSelector(getSoftwareBranch);
  const {
    total,
    changelog,
  } = useSelector(getChangelog);

  const hasListBranch = listBranch.length > 0;
  const hasListSoftware = listSoftware.length > 0;

  React.useEffect(() => {
    if (hasListBranch && hasListSoftware) {
      getChangeLog();
    }
  }, [params]);

  React.useEffect(() => {
    if (isInitialRender && hasListBranch && hasListSoftware) {
      getChangeLog();
    }
  }, [hasListSoftware, hasListBranch]);

  function getSoftwareId(getValue: { id: number }) {
    if (getValue) {
      setIsInitialRender(false);

      dispatch(branchClearData());
      dispatch(changelogClearData());

      const { id } = getValue;

      setParams({
        ...params,
        software_id: id,
        page: 1,
      });

      setPage(0);

      dispatch(loadSoftwareBranch({ software_id: id }));
    } else {
      dispatch(branchClearData());
      dispatch(changelogClearData());
    }
  }

  function getBranchName(getValue: { branch: string }) {
    if (getValue) {
      const { branch } = getValue;
      setParams({
        ...params,
        branch,
        page: 1,
      });
      setPage(0);
    } else {
      dispatch(changelogClearData());
    }
  }

  return (
    <Main
      title="Atualizações"
      header={(
        <>
          <Box p={1}>
            {hasListSoftware && (
              <ComboBoxAsynchronous
                loading={loadingSoftware}
                list={listSoftware}
                onChange={getSoftwareId}
                id="software-list"
                defaultValue={listSoftware.find((software) => software.id === sislojaId)}
              />
            )}
          </Box>
          {hasListBranch && (
            <Box p={1}>
              <ComboBoxAsynchronous
                loading={loadingBranch}
                list={listBranch}
                onChange={getBranchName}
                id="software-branch"
                label="Lista de Branch"
                defaultValue={isInitialRender ? listBranch.find((branch: any) => branch.branch === 'stable') : undefined}
              />
            </Box>
          )}
        </>
      )}
      content={(
        <UpdateTable
          changelog={changelog}
          count={total}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    />
  );
}
