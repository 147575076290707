import {
  createSelector,
  createSlice, Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';

import { apiCallBegan } from './api';
import { hello } from './auth';
import toastMessage from '../services/toastMessage.services';

interface SearchErrors {
  DATA_CRIADO: string;
  DATA_ENVIADO: string;
  CLIENTE: string;
  DESCRICAO: string;
  SISTEMA: string;
  VERSAO: string;
  COD_USUARIO: number;
  USUARIO: string;
  FORMULARIO: string;
  LINK_ERRO: string;
  id: number;
}

interface ErrorPerSystem {
  errorList: SearchErrors[],
  errorListFound: boolean,
  loading: boolean,
}

const initialState = {
  errorList: [],
  loading: false,
  errorListFound: false,
};

const message = 'Não foram encontrados erros';

const slice = createSlice({
  name: 'errorsPerSystem',
  initialState,
  reducers: {
    softwareErrorRequested: (software: Draft<ErrorPerSystem>): void => {
      software.loading = true;
    },
    softwareErrorRequestFailed: (software: Draft<ErrorPerSystem>) => {
      software.loading = false;
      software.errorListFound = true;

      toastMessage({ message, types: 'INFO' });

      software.errorList = [];
    },
    softwareErrorReceived: (software: Draft<ErrorPerSystem>, action: PayloadAction<any>) => {
      software.loading = false;
      software.errorListFound = false;

      software.errorList = action.payload.response_data.map((sync: any, index: number) => {
        sync.id = index;
        return sync;
      });
    },
    softwareErrorClear: (software: Draft<ErrorPerSystem>) => {
      software.errorList = [];
    },
  },
});

const {
  softwareErrorRequested,
  softwareErrorRequestFailed,
  softwareErrorReceived,
  softwareErrorClear,
} = slice.actions;

export default slice.reducer;

// Action Creators
const url = 'errors/searchErrors';

type tokenType = {
  readonly token: string,
}

export function getDateSoftware(initialDate: Date, endDate: Date, software_id: number) {
  return (dispatch: Function, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const fromDate: string = moment(new Date(initialDate)).format('DD/MM/YYYY');
    const toDate = moment(new Date(endDate)).format('DD/MM/YYYY');

    // api request for list software - action
    const request = {
      url,
      params: {
        fromDate,
        toDate,
        software_id,
      },
      header: { Authorization: `Bearer ${token}` },
      onStart: softwareErrorRequested.type,
      onSuccess: softwareErrorReceived.type,
      onError: softwareErrorRequestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

export function clearErrorList() {
  return (dispatch: Function) => {
    dispatch(softwareErrorClear());
  };
}

/* Selectors */

interface State {
  entities: {
    errorsPerSystem: ErrorPerSystem
  }
}

export const getErrorPerSystem = createSelector(
  (state: State) => state.entities.errorsPerSystem,
  (errorsPerSystem: ErrorPerSystem) => errorsPerSystem,
);
