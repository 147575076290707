import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;  
`;

export const Content = styled.div`
  justify-content: space-evenly;  
  
  .chip{
    margin-left: 1rem;
    margin-bottom: 1rem;
    width: 250px;
  }
`;
