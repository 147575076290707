import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  min-height: 200px;

  .header-title{
    width: 100%;
  }

  .header-text {
    font-family: 'Open Sans', sans-serif;
    color: rgba(45, 62, 94, 0.9);
    margin: 10px 0 0 20px;
    font-size: 28px;
    font-weight: 600;
  }  
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const Content = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  .card-content {
    width: 100%;
    min-height: 65vh;
    padding: 10px;
  }
`;
