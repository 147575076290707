import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Typography } from '@material-ui/core';

const VersionGraphicItem = ({ list, title }: any) => (
  <div style={{ width: '100%', height: '60vh' }}>
    <Typography style={{ textAlign: 'center', padding: '1px' }} color="primary">
      {title}
    </Typography>
    <ResponsivePie
      data={list}
      margin={{
        top: 40, right: 100, bottom: 120, left: 130,
      }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'category10' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor={{ theme: 'labels.text.fill' }}
      radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
    />
  </div>
);

export default VersionGraphicItem;
