import * as React from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import ListItem from '@material-ui/core/ListItem';
import { useSelector } from 'react-redux';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress, Tooltip, Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { getDocumentSent } from '../../../store/documentSent';
import { Cliente } from '../types';
import TypographyDate from '../../../components/TypographyDate';
import { DocumentType } from './types';
import { cnpjFormat } from '../../../lib/cnpj';
import clipboard from '../../../services/api/api.clipboard';
import { ResendActionButton } from '../ResendActionButtton';
import { DisableIssuesDocument } from '../DisableIssuesDocument';

const columns: GridColDef[] = [
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    renderCell: ({ value }: GridCellParams) => <TypographyDate date={value as string} />,
  },
  {
    field: 'client',
    headerName: 'Razão Social',
    width: 200,
    renderCell: ({ value }: GridCellParams) => {
      const { fantasy_name } = value as Cliente;
      return (
        <Tooltip title={fantasy_name}>
          <p style={{ textTransform: 'uppercase' }}>{fantasy_name}</p>
        </Tooltip>
      );
    },
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    width: 200,
    valueGetter: (params) => {
      const { cnpj } = params.getValue('client') as Cliente;
      return `${cnpjFormat(cnpj)}`;
    },
  },
  {
    field: 'document_type',
    headerName: 'Documento',
    width: 120,
    renderCell: ({ value }: GridCellParams) => {
      const type = value as number;
      let description: DocumentType = '';

      switch (type) {
        case 0:
          description = 'Nenhum tipo de documento';
          return (
            <Tooltip title={description}>
              <p>{description}</p>
            </Tooltip>
          );
        case 1:
          return <p>SAT</p>;
        case 2:
          return <p>NFe</p>;
        case 3:
          return <p>NFCe</p>;
        default:
          return <p />;
      }
    },
  },
  {
    field: 'email_sent',
    headerName: 'Enviados',
    width: 300,
    renderCell: ({ value }: GridCellParams) => {
      const emails = value as string;
      const [email] = emails.split(';', 2);
      return (
        <>
          {value
            ? (
              <ListItem button onClick={() => clipboard({ copy: email })}>
                <Typography component="p" variant="body2">
                  {email}
                </Typography>
              </ListItem>
            )
            : (
              <IconButton>
                <NotInterestedIcon />
              </IconButton>
            )}
        </>
      );
    },
  },
  {
    field: 'errors',
    headerName: 'Erros',
    width: 100,
    renderCell: ({ value }: GridCellParams) => {
      const error = value as number;
      return (
        <>
          {error === 1
            ? <Typography color="secondary">Sim</Typography>
            : <Typography color="primary">Não</Typography>}
        </>
      );
    },
  },
  {
    field: 'reason',
    headerName: 'Observações',
    width: 300,
    renderCell: ({ value }: GridCellParams) => (
      <Tooltip title={value as string}>
        <p>{value}</p>
      </Tooltip>
    ),
  },
  {
    field: 'client_id',
    headerName: 'Ações',
    width: 150,
    renderCell: (props: GridCellParams) => {
      const fantasy_name = props.row.client.fantasy_name as string;
      const client_id: number = props.value as number;
      return (
        <>
          <ResendActionButton
            client_id={client_id}
            fantasy_name={fantasy_name}
          />
          <DisableIssuesDocument
            client_id={client_id}
            fantasy_name={fantasy_name}
          />
        </>
      );
    },
  },
];

interface DataGridDocumentSentProps {
  count: number,
  page
    :
    number,
  onChangePage
    :
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  rowsPerPage: number;
  onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export default function DataGridDocumentSent(props: DataGridDocumentSentProps) {
  const {
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPage,
    count,
    page,
  } = props;
  const {
    documentSentList,
    loadingDocumentSent,
  } = useSelector(getDocumentSent);

  return (
    <>
      {
        loadingDocumentSent
          ? <LinearProgress />
          : (
            <div style={{
              display: 'flex',
              height: '100%',
            }}
            >
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  columnBuffer={7}
                  rows={documentSentList}
                  columns={columns}
                  autoHeight
                  disableSelectionOnClick
                  disableColumnMenu
                  disableColumnFilter
                  hideFooterPagination
                  loading={loadingDocumentSent}
                />
              </div>
            </div>
          )
      }
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}
      >
        <TablePagination
          component="div"
          count={count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    </>
  );
}
