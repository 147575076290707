import { Typography } from '@material-ui/core';
import React from 'react';
import { SynchronizedDateProps } from './types';

export function SynchronizedDate({ date }: SynchronizedDateProps) {
  let dateFormatted: string;

  try {
    dateFormatted = new Intl.DateTimeFormat('pt-BR').format(new Date(date));
  } catch (error) {
    dateFormatted = '';
  }

  return (
    <div>
      <Typography variant="button">
        {dateFormatted ? 'sincronizados' : 'nunca sincronizado' }
      </Typography>
      <Typography variant="subtitle2">
        {dateFormatted}
      </Typography>
    </div>
  );
}
