import { combineReducers } from 'redux';
import synchronized from './synchronized/synchronized';
import auth from './auth';
import login from './login';
import errorsPerSystem from './errorsPerSystem';
import updateChangelog from './updateChangelog';
import download from './download';
import versions from './versions';
import customers from './customers';
import officeUsers from './officeUser';
import documentSent from './documentSent';
import users from './users';

export default combineReducers({
  synchronized,
  auth,
  login,
  errorsPerSystem,
  download,
  updateChangelog,
  versions,
  customers,
  officeUsers,
  documentSent,
  users,
});
