import React, { ChangeEvent } from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Collapse,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  getCustomers,
  CustomerParams,
  CustomerParamsKeys,
  searchCustomers,
  storeSearchState,
  fetchActiveBranches,
} from '../../store/customers';
import useStylesSearch from './styles/CustomerSearch.module';
import FlexContainer from '../../components/Flex/FlexContainer';
import FormLabelStyled from '../../components/FormLabelStyled';

import {
  dataBaseTypeCustomerFormControl,
  defaultCustomerFormControl,
  satEmissionCustomerTypeFormControl,
  statusCustomerFormControl,
  clearanceCustomerFormControl,
} from './CustomerFilterOptions/customerFilterOptions';
import CustomerFormGroup from './Form';
import CustomerList from './CustomerList';
import CustomerFilter from './CustomerFilterOptions';
import { Main } from '../../components/Main';
import history from '../../services/history.services';

const initialState: CustomerParams = {
  status: 1,
  page: 1,
  qtd: 9,
  search: '',
  order_by: 1,
};

interface filterInitialStateProps {
  sat_manager: number;
  status: number;
  database_type: number;
  test: number;
  sat_emission_type: number;
  branch: string;
  clearance: -1 | 0 | 1 | 2 | 3;
}

const filterInitialState: filterInitialStateProps = {
  sat_manager: -1,
  status: 1,
  database_type: -1,
  test: -1,
  sat_emission_type: -1,
  branch: 'todos',
  clearance: -1,
};

export default function Customer() {
  const classes = useStylesSearch();

  const dispatch = useDispatch();
  const { customerTotalSearch, searchState, activeBranches } = useSelector(getCustomers);

  // pagination
  const [count, setCount] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(searchState.customerParam.page);

  // filter
  const [filterState, setFilterState] = React.useState<filterInitialStateProps>(
    { ...searchState.filters },
  );

  // param search
  const [customerParam, setCustomerParam] = React.useState<CustomerParams>(
    { ...searchState.customerParam },
  );

  // search values

  const [searchValue, setSearchValues] = React.useState<string>(searchState.customerParam.search);
  // get values input search
  const getInputValuesSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = e.target.value;

    const cnpj = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    const isCnpj = cnpj.test(search);

    // check is cnpj
    if (isCnpj) {
      const cnpjValue = search
        .replace(/\./g, '')
        .replace(/\//g, '')
        .replace(/-/g, '');

      setSearchValues(search);
      setCustomerParam({
        ...customerParam,
        search: cnpjValue,
      });
    }

    if (!isCnpj) {
      setSearchValues(search);
      setCustomerParam({
        ...customerParam,
        search,
      });
    }
  };

  // request search
  const getSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPage(1);
    setCustomerParam({
      ...customerParam,
      page: 1,
    });
    dispatch(searchCustomers({
      ...customerParam,
      page: 1,
    }));
  };

  // clear input values search
  const clearSearchInput = () => {
    const search = '';
    setSearchValues(search);
    setCustomerParam({
      ...customerParam,
      search,
    });
  };

  // clear filter

  const clearFilterInput = () => {
    setFilterState(filterInitialState);
    setCustomerParam(initialState);
    setPage(1);
    setSearchValues('');
  };

  // pagination

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCustomerParam({
      ...customerParam,
      page: value,
    });
  };

  const countPage = () => {
    const result = Math.ceil(customerTotalSearch / 9);
    setCount(result);
  };

  React.useEffect(() => {
    countPage();
  }, [customerTotalSearch]);

  // end pagination

  React.useEffect(() => {
    dispatch(searchCustomers(customerParam));
  }, [page, filterState]);

  React.useEffect(() => {
    dispatch(fetchActiveBranches());
  }, []);

  React.useEffect(() => {
    dispatch(storeSearchState({
      filters: filterState,
      customerParam: {
        ...customerParam,
        search: searchValue,
      },
    }));
  }, [customerParam, filterState]);

  // listen to route changes
  React.useEffect(() => {
    function resetSearchState(path: string) {
      if (!path.startsWith('/customers/')) {
        dispatch(storeSearchState({
          filters: filterInitialState,
          customerParam: {
            ...initialState,
            search: '',
          },
        }));
      }
    }

    const unlisten = history.listen((location) => {
      resetSearchState(location.pathname);
    });

    return unlisten;
  }, []);

  // change values params
  const changeOptionsFormControl = (option: number | string, key: CustomerParamsKeys): void => {
    setPage(1);
    setFilterState({
      ...filterState,
      [key]: option,
    });
    const select = option === -1 || option === 'todos';
    // remove element in object
    if (select) delete customerParam[key];
    setCustomerParam(customerParam);

    // add new element and values
    if (!select) {
      setCustomerParam({
        ...customerParam,
        [key]: option,
        page: 1,
      });
    }
  };

  // expanded collapse
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Main
      title="Clientes"
      header={(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomerFormGroup row>
            <form onSubmit={getSearch}>
              <Paper className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Busque por razão social, cnpj ou nome fantasia,"
                  onChange={getInputValuesSearch}
                  value={searchValue}
                />
                {searchValue.length > 0 && (
                  <IconButton
                    type="button"
                    className={classes.iconButton}
                    aria-label="clear-search"
                    onClick={clearSearchInput}
                  >
                    <ClearIcon style={{
                      width: '20px',
                      height: '20px',
                    }}
                    />
                  </IconButton>
                )}
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <Button
                  color={expanded ? 'primary' : 'default'}
                  onClick={handleExpandClick}
                  startIcon={<FilterListIcon />}
                >
                  Filtros
                </Button>
              </Paper>
            </form>
          </CustomerFormGroup>
          <Collapse in={expanded} style={{ width: '100%' }}>
            <CustomerFormGroup row>
              <Card style={{ width: '100%' }}>
                <FlexContainer style={{ width: '100%' }}>
                  <CustomerFilter
                    label="Gerenciador sat"
                    onChange={changeOptionsFormControl}
                    nameLabel="sat_manager"
                    formControl={defaultCustomerFormControl}
                    value={filterState.sat_manager}

                  />
                  <CustomerFilter
                    label="Status"
                    onChange={changeOptionsFormControl}
                    nameLabel="status"
                    formControl={statusCustomerFormControl}
                    value={filterState.status}
                  />
                  <CustomerFilter
                    label="Teste"
                    onChange={changeOptionsFormControl}
                    nameLabel="test"
                    formControl={defaultCustomerFormControl}
                    value={filterState.test}
                  />
                  <CustomerFilter
                    label="Emissão sat"
                    onChange={changeOptionsFormControl}
                    nameLabel="sat_emission_type"
                    formControl={satEmissionCustomerTypeFormControl}
                    value={filterState.sat_emission_type}
                  />
                  <CustomerFilter
                    label="Banco de dados"
                    onChange={changeOptionsFormControl}
                    nameLabel="database_type"
                    formControl={dataBaseTypeCustomerFormControl}
                    value={filterState.database_type}
                  />
                  <CustomerFilter
                    label="Liberação"
                    onChange={changeOptionsFormControl}
                    nameLabel="clearance"
                    formControl={clearanceCustomerFormControl}
                    value={filterState.clearance}
                  />
                  <FormControl style={{ marginTop: 10 }}>
                    <FormLabelStyled id="filter-branches">Branch</FormLabelStyled>
                    <Select id="filter-branches" value={filterState.branch} onChange={(e) => changeOptionsFormControl(e.target.value as string, 'branch')}>
                      <MenuItem value="todos">Todos</MenuItem>
                      {activeBranches.map(({ branch }) => (
                        <MenuItem value={branch} key={branch}>{branch}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FlexContainer>
                <Divider />
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  startIcon={<DeleteIcon />}
                  style={{ margin: '10px' }}
                  onClick={clearFilterInput}
                >
                  Limpar
                </Button>
              </Card>
            </CustomerFormGroup>
          </Collapse>
          <CustomerFormGroup row>
            <div className={classes.root}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ marginLeft: '15px' }}
              >
                {customerTotalSearch > 1
                  ? `${customerTotalSearch} resultados`
                  : `${customerTotalSearch} resultado`}
              </Typography>
            </div>
          </CustomerFormGroup>
        </div>
      )}
      content={(
        <CustomerList
          count={count}
          page={page}
          onChange={handleChangePage}
          hidden={customerTotalSearch < 10}
        />
      )}
    />
  );
}
