import { Link } from 'react-router-dom';
import { CardActions, Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import GitHubIcon from '@material-ui/icons/GitHub';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

import React from 'react';
import moment from 'moment';
import useStylesCustomer from '../styles/Customer.module';

interface CustomerCardActionsProps {
  customer: {
    clearance: number | undefined;
    last_sync: string | undefined;
    branch: string | undefined;
    status: number | undefined;
    id: number;
  }
}

const CustomerCardActions = ({ customer }: CustomerCardActionsProps) => {
  const {
    clearance,
    last_sync,
    branch,
    status,
  } = customer;

  const classes = useStylesCustomer();

  const formatDate = (date: string | undefined) => moment(new Date(date as string))
    .format('DD/MM/yyyy');

  const isLastSync = last_sync !== null;

  interface ClearanceMessage {
    icon: React.ReactElement;
    message: 'liberado' | 'em atraso' | 'bloqueado' | 'não identificado';
    color: string,
  }

  // clearance tem 3 status: 1 e 3 Liberado; 2 mensagem de atraso; 0 bloqueado
  const clearanceMessage = (clearanceStatus: number | undefined): ClearanceMessage => {
    switch (clearanceStatus) {
      case 0:
        return {
          icon: <CloseIcon style={{ fill: '#fff' }} />,
          message: 'bloqueado',
          color: classes.block,
        };
      case 1:
      case 3:
        return {
          icon: <DoneIcon style={{ fill: '#fff' }} />,
          message: 'liberado',
          color: classes.released,
        };
      case 2:
        return {
          icon: <InfoOutlinedIcon style={{ fill: '#000' }} />,
          message: 'em atraso',
          color: classes.delay,
        };
      default:
        return {
          icon: <CloseIcon style={{ fill: '#fff' }} />,
          message: 'não identificado',
          color: classes.block,
        };
    }
  };

  return (
    <CardActions>
      <div className={classes.chip}>
        <Chip
          className={clearanceMessage(clearance).color}
          label={clearanceMessage(clearance).message}
          size="small"
          icon={clearanceMessage(clearance).icon}
        />
        <Chip
          label={isLastSync ? formatDate(last_sync) : 'não informado'}
          variant="outlined"
          size="small"
          color={isLastSync ? 'primary' : 'secondary'}
          icon={isLastSync ? <SyncIcon /> : <SyncProblemIcon />}
        />
        <Chip
          label={branch}
          variant="outlined"
          size="small"
          color="primary"
          icon={<GitHubIcon />}
        />
        <Chip
          label={status === 1
            ? 'ativo'
            : 'inativo'}
          variant="outlined"
          size="small"
          color={status === 1
            ? 'primary'
            : 'secondary'}
          icon={status === 1
            ? <DoneIcon />
            : <CloseIcon />}
        />
        <Chip
          label="módulos"
          variant="outlined"
          size="small"
          icon={<ExtensionOutlinedIcon />}
          color="primary"
          component={Link}
          to={`/customers/modules/${customer.id}`}
          clickable
        />
        <Chip
          label="terminais"
          variant="outlined"
          size="small"
          icon={<DesktopWindowsIcon />}
          color="primary"
          component={Link}
          to={`/customers/terminals/${customer.id}`}
          clickable
        />
      </div>
    </CardActions>
  );
};

export default CustomerCardActions;
