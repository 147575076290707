import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import { Content } from './styles';
import Copyright from '../../components/Copyright';
import logo from '../../assets/media/logo96.png';
import { LoginButton } from './Button';
import { LoginForm } from './Form';
import { selectorAuth } from '../../store/auth';
import { clearInputs } from '../../store/login';
import AppVersion from '../../components/AppVersion';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(selectorAuth);

  React.useEffect(() => {
    if (loggedIn) {
      // wipe value credentials
      dispatch(clearInputs());
    }
  }, [loggedIn]);

  return (
    <Box m={10}>
      <Container component="main" maxWidth="xs">
        <Card>
          <CardContent>
            <CssBaseline />
            <Content>
              <header>
                <img src={logo} alt="logo cloud" />
              </header>
              <LoginForm />
              <LoginButton />
              <footer>
                <Box mt={8}>
                  <Copyright />
                </Box>
                <AppVersion />
              </footer>
            </Content>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default withRouter(Login);
