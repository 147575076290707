import axios from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import * as actions from '../api';
import history from '../../services/history.services';
import { ApiCallBegan } from '../api';
import env from '../../env';

interface StateType {
  dispatch: Dispatch,
  getState: () => any,
}

const api = ({ dispatch }: StateType) => (next: Function) => async (action: {
  type: string,
  payload: ApiCallBegan
}) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const {
    url,
    method,
    responseType,
    data,
    params,
    onStart,
    onSuccess,
    onError,
    header,
    redirect,
  } = action.payload;

  if (onStart) dispatch({ type: onStart });

  next(action);

  try {
    const response = await axios.request({
      baseURL: env.REACT_APP_BASE_URL,
      url,
      method,
      responseType,
      data,
      params,
      headers: header,
    });
    // General
    dispatch(actions.apiCallSuccess(response.data));
    // Specific
    if (onSuccess) {
      dispatch({
        type: onSuccess,
        payload: response.data,
      });
    }
    if (redirect) history.push(redirect);

    return undefined;
  } catch (error: any) {
    // case errors
    if (error.response) {
      const { message } = error.response.data;
      // General
      dispatch(actions.apiCallFailed(message));
      // Specific
      if (onError) {
        dispatch({
          type: onError,
          payload: message,
        });
      }
    } else if (error.request) {
      // General
      dispatch(actions.apiCallFailed(error.request));
      // Specific
      if (onError) {
        dispatch({
          type: onError,
          payload: 'error.request',
        });
      }
    } else {
      const { message } = error;
      // General
      dispatch(actions.apiCallFailed(message));
      // Specific
      if (onError) {
        dispatch({
          type: onError,
          payload: message,
        });
      }
    }
    return undefined;
  }
};

export default api;
