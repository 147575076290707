import React from 'react';
import {
  Avatar,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

import BusinessIcon from '@material-ui/icons/Business';
import moment from 'moment';
import TypographyCnpj from '../../../components/TypographyCnpj';
import TypographyDataBaseType from '../../../components/TypographyDataBaseType';
import useStylesCustomer from '../styles/Customer.module';
import CustomerCardStyled from '../Card';
import CustomerCardActions from '../CustomerActions';

interface CustomerProps {
  customer: {
    id: number,
    created_at?: string,
    fantasy_name?: string,
    corporate_name: string,
    cnpj?: string,
    status?: number,
    clearance?: number,
    test?: number,
    branch?: string,
    last_sync?: string,
    database_type?: number,
    sat_emission_type?: number,
    sat_manager?: number,
    accounting_office: {
      fantasy_name: string,
    }
  }
}

export default function CustomerItem({ customer }: CustomerProps) {
  const {
    id,
    created_at,
    last_sync,
    branch,
    clearance,
    accounting_office,
    cnpj,
    fantasy_name,
    corporate_name,
    database_type,
    sat_emission_type,
    sat_manager,
    status,
    test,
  } = customer;

  const classes = useStylesCustomer();

  const formatDate = (date: string | undefined) => moment(new Date(date as string))
    .format('DD/MM/yyyy');

  const managerText = (type: number | undefined): string => {
    if (type === 1) return 'Sim';
    if (type === 0) return 'Não';
    return 'não informado';
  };

  const satEmission = (type: number | undefined): string => {
    if (type === 1) return 'Tecnospeed';
    if (type === 2) return 'ACBrLib';
    return 'não informado';
  };

  return (
    <CustomerCardStyled>
      <CardHeader
        style={{ textTransform: 'uppercase' }}
        avatar={(
          <Avatar className={classes.primary}>
            <BusinessIcon />
          </Avatar>
        )}
        title={fantasy_name}
        subheader={`Cadastrado em ${formatDate(created_at)}`}
      />
      <CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ textTransform: 'uppercase' }}
        >
          <strong>ID: </strong>
          {id}
        </Typography>
        <TypographyCnpj
          cnpj={cnpj}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ textTransform: 'uppercase' }}
        >
          <strong>Escritório: </strong>
          {accounting_office.fantasy_name}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ textTransform: 'uppercase' }}
        >
          <strong>Razão Social: </strong>
          {corporate_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Teste: </strong>
          {managerText(test)}
        </Typography>
        <TypographyDataBaseType
          dataBaseType={database_type}
        />
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Emissão Sat: </strong>
          {satEmission(sat_emission_type)}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>Gerenciador Sat: </strong>
          {managerText(sat_manager)}
        </Typography>
      </CardContent>
      <CustomerCardActions
        customer={{
          clearance,
          branch,
          last_sync,
          status,
          id,
        }}
      />
    </CustomerCardStyled>
  );
}
