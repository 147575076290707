import {
  createSelector,
  createSlice,
  Dispatch,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { hello } from './auth';
import { ApiCallBegan, apiCallBegan } from './api';
import toastMessage from '../services/toastMessage.services';

type Modules = {
  id: number,
  value: number,
  software_module: {
    id: number,
    software_id: number,
    description: string,
  },
  software: {
    id: number,
    name: string,
    download_path: string,
  },
}

type Computer = {
  id: number,
  pc_name: string,
  status: number,
  last_login: string,
  last_software: string,
  token: string,
  access_code: string | null,
  obs: string | null,
}

// responsible interface for using the following client properties
interface CustomerItem {
  id: number,
  created_at: string,
  name: string,
  fantasy_name: string,
  corporate_name: string,
  cnpj: string,
  ie: string,
  status: number,
  clearance: number,
  test: number,
  branch: string,
  last_sync: string,
  database_type: number,
  sat_emission_type: number,
  sat_manager: number,
  accounting_office: {
    fantasy_name: string,
  }
  modules: Modules[],
  computers: Computer[],
  control_computers: number,
  max_computers: number,
}

// response data
interface CustomerList {
  response_data: {
    data: CustomerItem[],
    total: number,
  }
}

interface FetchSingleCustomer {
  status: string,
  message: string,
  response_data: CustomerItem,
}

interface SearchState {
  filters: {
    sat_manager: number,
    status: number,
    database_type: number,
    test: number,
    sat_emission_type: number,
    branch: string,
    clearance: -1 | 0 | 1 | 2 | 3,
  },
  customerParam: {
    status?: 0 | 1,
    page: number,
    qtd: number,
    search: string,
    order_by?: 0 | 1,
  },
}

type Branch = {
  branch: string,
}

interface ActiveBranchesResponse {
  status: string,
  message: string,
  response_data: Branch[]
}

// interface initial state
interface Customer {
  customerList: CustomerItem[],
  customerLoading: boolean,
  customerTotalSearch: number,
  selectedCustomer?: CustomerItem,
  searchState: SearchState,
  activeBranches: Branch[],
}

const initialState: Customer = {
  customerList: [],
  customerLoading: false,
  customerTotalSearch: 0,
  searchState: {
    customerParam: {
      status: 1,
      page: 1,
      qtd: 9,
      search: '',
      order_by: 1,
    },
    filters: {
      sat_manager: -1,
      status: 1,
      database_type: -1,
      test: -1,
      sat_emission_type: -1,
      branch: 'todos',
      clearance: -1,
    },
  },
  activeBranches: [],
};

const slice = createSlice({
  name: 'api/clientes/search',
  initialState,
  reducers: {
    customerRequest: (customer: Draft<Customer>): void => {
      // request
      customer.customerLoading = true;
    },
    customerFailed: (customer: Draft<Customer>): void => {
      // failed request
      customer.customerLoading = false;
      customer.customerList = [];
      customer.customerTotalSearch = 0;
      // alert
      const message = 'Não encontrado';
      toastMessage({
        types: 'INFO',
        message,
      });
    },
    customerReceived: (customer: Draft<Customer>, action: PayloadAction<CustomerList>): void => {
      // success request
      customer.customerLoading = false;

      const {
        data,
        total,
      } = action.payload.response_data;

      // set data and total
      customer.customerList = data;
      customer.customerTotalSearch = total;
    },
    customersUpdated: (
      customer: Draft<Customer>,
      action: PayloadAction<FetchSingleCustomer>,
    ): void => {
      customer.customerLoading = false;

      if (action.payload.status === 'error') {
        toastMessage({
          types: 'INFO',
          message: action.payload.message,
        });
      } else {
        const indexOfUpdatedCustomer = customer.customerList.findIndex((customerItem) => (
          customerItem.id === action.payload.response_data.id
        ));

        customer.customerList[indexOfUpdatedCustomer] = action.payload.response_data;
        customer.selectedCustomer = action.payload.response_data;

        toastMessage({
          types: 'INFO',
          message: 'Cliente atualizado com sucesso',
        });
      }
    },
    singleCustomerReceived: (
      customer: Draft<Customer>,
      action: PayloadAction<FetchSingleCustomer>,
    ): void => {
      // success request
      customer.customerLoading = false;

      customer.selectedCustomer = action.payload.response_data;
    },
    searchStateStored: (
      customer: Draft<Customer>,
      action: PayloadAction<SearchState>,
    ): void => {
      customer.searchState = action.payload;
    },
    activeBranchesReceived: (
      customer: Draft<Customer>,
      action: PayloadAction<ActiveBranchesResponse>,
    ): void => {
      customer.customerLoading = false;

      customer.activeBranches = action.payload.response_data;
    },
    customerClearList: (customer: Draft<Customer>): void => {
      customer.customerList = [];
    },
  },
});

export default slice.reducer;

const {
  customerRequest,
  customerFailed,
  customerReceived,
  singleCustomerReceived,
  customersUpdated,
  searchStateStored,
  activeBranchesReceived,
} = slice.actions;

// interface params send api request
export interface CustomerParams {
  status?: 1 | 0, // 1 ativo, 0 invativo
  page: number,
  qtd: number,
  search?: string,
  test?: number, // 0 - Não; 1 - Sim;
  database_type?: number, //  1 - Access; 2 - Firebird; 3 - SQL Server; 4 - MariaDB;
  sat_emission_type?: number, // 1 - Tecnospeed; 2 - ACBrLib;
  sat_manager?: number, // 0 - Não; 1 - Sim;
  includeModules?: number, // 0 - Não; 1 - Sim;
  includeComputers?: number, // 0 - Não; 1 - Sim;
  order_by?: 1 | 0,
  branch?: string,
  clearance?: number, // 0 - bloqueado; 1 - liberado; 2 - alerta de pagamento; 3 - liberado
}

export type CustomerParamsKeys =
  'status'
  | 'page'
  | 'qtd'
  | 'search'
  | 'test'
  | 'database_type'
  | 'sat_emission_type'
  | 'sat_manager'
  | 'includeModules'
  | 'includeComputers'
  | 'order_by'
  | 'branch'
  | 'clearance';

interface State {
  entities: {
    auth: {
      token: string
    }
    customers: Customer;
  }
}

export function searchCustomers(params: CustomerParams) {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = 'clients/search';

    // get token
    let { token }: { token: string } = getState().entities.auth;
    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      url,
      header,
      params,
      onStart: customerRequest.type,
      onSuccess: customerReceived.type,
      onError: customerFailed.type,
    };

    dispatch(apiCallBegan(request));
  };
}

export function fetchCustomer(params: {id: string}) {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = `/client/${params.id}`;

    let { token }: { token: string } = getState().entities.auth;
    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      url,
      header,
      onStart: customerRequest.type,
      onSuccess: singleCustomerReceived.type,
      onError: customerFailed.type,
    };

    dispatch(apiCallBegan(request));
  };
}

export function fetchActiveBranches() {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = '/clients/activeBranchs';

    let { token }: { token: string } = getState().entities.auth;
    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      url,
      header,
      onStart: customerRequest.type,
      onSuccess: activeBranchesReceived.type,
      onError: customerFailed.type,
    };

    dispatch(apiCallBegan(request));
  };
}
interface UpdateCustomerModulesParams {
  id: number,
  modules: Modules[],
}

export function updateCustomerModules(data: UpdateCustomerModulesParams) {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = '/client/module';

    let { token }: { token: string } = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      method: 'put',
      url,
      header,
      data,
      onStart: customerRequest.type,
      onSuccess: customersUpdated.type,
      onError: customerFailed.type,
    };

    dispatch(apiCallBegan(request));
  };
}

interface UpdateComputerParams {
  id: number,
  computers: Computer[],
}

export function updateComputer(data: UpdateComputerParams) {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = '/client/computer';

    let { token }: { token: string } = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      method: 'put',
      url,
      header,
      data,
      onStart: customerRequest.type,
      onSuccess: customersUpdated.type,
      onError: customerFailed.type,
    };

    dispatch(apiCallBegan(request));
  };
}

interface UpdateCustomerTerminalsParams {
  id: number,
  max_computers: number,
  control_computers: number,
  name: string,
  fantasy_name: string,
  clearance: number,
  branch: string,
}

export function updateComputersOptions(params: UpdateCustomerTerminalsParams) {
  return (dispatch: Dispatch, getState: () => State): void => {
    const url = `/client/update/${params.id}`;
    const data = { ...params } as any;
    delete data.id;

    let { token }: { token: string } = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const header = { Authorization: `Bearer ${token}` };

    const request: ApiCallBegan = {
      method: 'put',
      url,
      header,
      data,
      onStart: customerRequest.type,
      onSuccess: customersUpdated.type,
      onError: customersUpdated.type,
    };

    dispatch(apiCallBegan(request));
  };
}

export function storeSearchState(state: SearchState) {
  return (dispatch: Dispatch): void => {
    dispatch(searchStateStored(state));
  };
}

interface GetCustomer {
  entities: {
    customers: Customer,
  },
}

// selectors
export const getCustomers = createSelector(
  (state: GetCustomer) => state.entities.customers,
  (customer: Customer) => customer,
);
