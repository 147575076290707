import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 8px 8px 12px;
  width: 720px;
`;

export default FlexContainer;
