import Grid from '@material-ui/core/Grid';
import React from 'react';
import image from '../../../assets/media/download-sat-report.svg';

export function Content() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >

      <Grid item xs={12}>
        <img src={image} width="400" alt="" />
      </Grid>
    </Grid>
  );
}
