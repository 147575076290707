import React from 'react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FlexColumn from '../../../components/Flex/FlexColumn';
import FormControlStyled from '../../../components/FormControlStyled';
import FormLabelStyled from '../../../components/FormLabelStyled';
import { CustomerParamsKeys } from '../../../store/customers';

type Default = 'Sim' | 'Não' | 'Todos';
type Status = 'Ativo' | 'Inativo';
type Sat = 'Tecnospeed' | 'ACBrLib';
type DataBase = 'Access' | 'Firebird' | 'SQL Server' | 'MariaDB';
type Clearance = 'Bloqueado' | 'Liberado' | 'Alerta de pagamento';

type FormControlLabelProps = Default | Sat | DataBase | Status | Clearance;

export interface FormControlLabelPropsTest {
  value: number,
  label: FormControlLabelProps,
}

interface CustomerFilterProps {
  label: string;
  onChange: (option: number, key: CustomerParamsKeys) => void;
  nameLabel: CustomerParamsKeys,
  formControl: FormControlLabelPropsTest[];
  value: number;
}

export default function CustomerFilter(props: CustomerFilterProps) {
  const {
    label,
    onChange,
    nameLabel,
    formControl,
    value,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get values prop name and value
    const key: CustomerParamsKeys = (event.target as HTMLInputElement).name as CustomerParamsKeys;
    const option = +(event.target as HTMLInputElement).value as number;
    // update customerParams
    onChange(option, key);
  };

  return (
    <FlexColumn>
      <FormControlStyled>
        <FormLabelStyled>{label}</FormLabelStyled>
        <RadioGroup
          aria-label={label}
          name={label}
          value={value}
          onChange={handleChange}
        >
          {formControl.map((data, index: number) => (
            <FormControlLabel
              key={index as number}
              value={data.value}
              control={<Radio color="primary" />}
              label={data.label}
              name={nameLabel}

            />
          ))}
        </RadioGroup>
      </FormControlStyled>
    </FlexColumn>
  );
}
