import {
  Box,
  Button,
  createStyles, Fab,
  makeStyles,
  Switch, Theme,
  Typography,
} from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Main } from '../../components/Main';
import { Permissions, selectorAuth } from '../../store/auth';
import {
  getUsers, loadUser, updateUser, User,
} from '../../store/users';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { EditDialog } from './EditDialog';

type PermissionsMap<T> = {
  [key in keyof T]: string;
}

const permissionsMap: PermissionsMap<Permissions> = {
  admin: 'Administrador',
  owner: 'Proprietário',
  register_client: 'Cadastro de cliente',
  update_client: 'Atualizar cliente',
  register_line_of_work: 'Cadastro de ramo de trabalho',
  register_software: 'Cadastro de software',
  register_update: 'Cadastro de atualização',
  register_accounting_office: 'Cadastro de escritório',
  register_software_module: 'Cadastro de módulo',
  view_logs: 'Visualização de logs',
  view_branchs: 'Visualização de branchs',
  view_errors: 'Visualização de erros',
  view_dashboard: 'Visualização de dashboard',
  view_analytics: 'Visualização de analytics',
  view_client_information: 'Visualização de informações do cliente',
  register_company: 'Cadastro de empresas',
  register_city: 'Cadastro de cidades',
  register_user: 'Cadastro de usuários',
  view_detach_xml: 'Visualização de separar xml',
  view_client_sync: 'Visualização de sincronização',
  view_client_errors: 'Visualização de erros por cliente',
  view_client_version: 'Visualização de versões por clientes',
  view_none_doc_issued: 'Visualização de gerar relatório SAT',
  view_software_updates: 'Visualização de atualizações',
  view_client_list: 'Visualização de lista de clientes',
  view_acc_office_users: 'Visualização de usuários de escritório',
  update_acc_office_users: 'Atualizar usuários de escritório',
  view_software_module: 'Visualização de módulos',
  view_sent_documents: 'Visualização de documentos enviados',
  view_client_modules: 'Visualização de módulos por cliente',
  update_client_modules: 'Atualizar módulos por clientes',
  view_client_computers: 'Visualização de terminais de clientes',
  update_client_computers: 'Atualizar de terminais de clientes',
  view_users: 'Visualização de usuários',
  update_users: 'Atualizar usuários',
};

interface ConfirmEditDialogParams {
  id: number,
  name: string,
  email: string,
  status: number,
}

interface ConfirmPasswordDialogParams {
  id: number,
  name: string,
  email: string,
  status: number,
  password: string,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  fab: {
    margin: 0,
    top: 'auto',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  bold: {
    fontWeight: 'bold',

    '& span': {
      fontWeight: 'normal',
    },
  },
  btnContainer: {
    marginTop: theme.spacing(2),

    '& button + button': {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default function UserDetails() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { selectedUser: user } = useSelector(getUsers);
  const { permissions: authPermissions } = useSelector(selectorAuth);

  const [userBeingEdited, setUserBeingEdited] = useState<User>({} as User);
  const [showFab, setShowFab] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  useEffect(() => {
    dispatch(loadUser({ id: Number(id) }));
  }, []);

  useEffect(() => {
    if (user) {
      setUserBeingEdited({ ...user });
    }
  }, [user]);

  useEffect(() => {
    setShowFab(JSON.stringify(userBeingEdited.permissions) !== JSON.stringify(user?.permissions));
  }, [userBeingEdited]);

  if (!userBeingEdited.id || !user) {
    return null;
  }

  const handlePermissionChange = (key: keyof Permissions) => {
    const newPermissions = { ...userBeingEdited.permissions };

    newPermissions[key] = newPermissions[key] === 1 ? 0 : 1;

    setUserBeingEdited({ ...userBeingEdited, permissions: newPermissions });
  };

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: 'Ativa',
      width: 100,
      renderCell: (params: GridCellParams) => {
        if (authPermissions.update_users === 1) {
          return <Switch color="primary" checked={params.value === 1} onChange={() => handlePermissionChange(params.row.id as keyof Permissions)} />;
        }

        return <>{params.value === 1 ? 'Sim' : 'Não'}</>;
      },
    },
    {
      field: 'title',
      headerName: 'Permissão',
      flex: 1,
    },
  ];

  // eslint-disable-next-line max-len
  const rows = Object.keys(userBeingEdited.permissions).filter((key) => permissionsMap[key as keyof Permissions])
    .map((key) => (
      {
        id: key,
        title: permissionsMap[key as keyof Permissions] ?? key,
        value: userBeingEdited.permissions[key as keyof Permissions],
      }
    ));

  const confirmPermissionChanges = () => {
    dispatch(updateUser(
      {
        id: user.id,
        name: user.name,
        email: user.email,
        status: user.status,
        permissions: userBeingEdited.permissions,
      },
    ));
  };

  const confirmEditDialog = (userInfo: ConfirmEditDialogParams) => {
    dispatch(updateUser(userInfo));

    setShowEditDialog(false);
  };

  const confirmPasswordDialog = (userInfo: ConfirmPasswordDialogParams) => {
    dispatch(updateUser(userInfo));

    setShowPasswordDialog(false);
  };

  const cancelEditDialog = () => {
    setShowEditDialog(false);
  };

  const cancelPasswordDialog = () => {
    setShowPasswordDialog(false);
  };

  return (
    <>
      {authPermissions.update_users === 1 && showFab
        && <Fab variant="extended" color="primary" onClick={confirmPermissionChanges} className={classes.fab}>Salvar alterações</Fab>}
      <Main
        title={userBeingEdited.name}
        header={(
          <Box textAlign="left" width="100%" pl={2.5} pt={4}>
            <Typography className={classes.bold}>
              Email:
              {' '}
              <span>{userBeingEdited.email}</span>
            </Typography>
            <Typography className={classes.bold}>
              Ativo:
              {' '}
              <span>{userBeingEdited.status === 1 ? 'Sim' : 'Não'}</span>
            </Typography>
            <Box className={classes.btnContainer} mt={2}>
              <Button color="primary" variant="contained" onClick={() => setShowEditDialog(true)}>Editar</Button>
              <Button color="primary" onClick={() => setShowPasswordDialog(true)}>Alterar senha</Button>
            </Box>
          </Box>
        )}
        content={<DataGrid autoHeight columns={columns} rows={rows} />}
      />

      <EditDialog
        dialogProps={{ open: showEditDialog }}
        userBeingEdited={userBeingEdited}
        onConfirm={confirmEditDialog}
        onCancel={cancelEditDialog}
      />
      <ChangePasswordDialog
        dialogProps={{ open: showPasswordDialog }}
        userBeingEdited={userBeingEdited}
        onConfirm={confirmPasswordDialog}
        onCancel={cancelPasswordDialog}
      />
    </>
  );
}
