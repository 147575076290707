import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { hello } from '../../store/auth';

type PropsPrivateRoute = {
  path: string;
  children: React.ReactNode;
};

const PrivateRoute = (props: PropsPrivateRoute) => {
  const { children } = props;

  const permission: string = sessionStorage.getItem(hello)!;

  return (
    <Route {...props}>

      {permission && children}
      {!permission && <Redirect to={{ pathname: '/login' }} />}
    </Route>
  );
};

export default PrivateRoute;
