import { createAction } from '@reduxjs/toolkit';
import { Method, ResponseType } from 'axios';

export interface ApiCallBegan {
  url: string,
  header?: any,
  method?: Method,
  params?: any,
  data?: any
  onStart: string,
  onSuccess: string,
  onError: string,
  responseType?: ResponseType,
  redirect?: string,
}

export const apiCallBegan = createAction<ApiCallBegan>('api/callBegan');
export const apiCallSuccess = createAction<{}>('api/callSuccess');
export const apiCallFailed = createAction<{}>('api/callFailed');
