import React from 'react';
import { Main } from '../../components/Main';
import { Header } from './Header';
import { Content } from './Content';

export default function SatReport() {
  return (
    <Main
      title="Relatório SAT"
      header={<Header />}
      content={<Content />}
    />
  );
}
