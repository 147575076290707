import {
  createSelector, createSlice, Dispatch, Draft, PayloadAction,
} from '@reduxjs/toolkit';
import { hello } from '../auth';
import { ApiCallBegan, apiCallBegan } from '../api';
import toastMessage from '../../services/toastMessage.services';

interface SoftwareModule {
    id : number;
    software_id: number;
    description : string;
    created_at : string;
}

interface SoftwareModulesList {
    listModules: SoftwareModule[],
    loadingModules: boolean,
}

const initialState: SoftwareModulesList = {
  listModules: [],
  loadingModules: false,
};

const slice = createSlice(
  {
    name: 'api/software/modules',
    initialState,
    reducers: {
      modulesRequested: (software: Draft<SoftwareModulesList>): void => {
        software.loadingModules = true;
      },
      modulesRequestFailed: (software: Draft<SoftwareModulesList>, action: PayloadAction<any>) => {
        software.loadingModules = false;
        const message = action.payload;

        toastMessage({ message, types: 'INFO' });
      },
      modulesReceived: (software: Draft<SoftwareModulesList>, action: PayloadAction<any>): void => {
        software.loadingModules = false;
        software.listModules = action.payload.response_data;
      },
      moduleAdded: (software: Draft<SoftwareModulesList>, action: PayloadAction<any>): void => {
        software.loadingModules = false;
        software.listModules.push(action.payload.response_data);
      },
      modulesClear: (software: Draft<SoftwareModulesList>): void => {
        software.listModules = [];
      },
    },
  },
);

export default slice.reducer;

const {
  modulesRequested,
  modulesRequestFailed,
  modulesReceived,
  moduleAdded,
  modulesClear,
} = slice.actions;

type tokenType = {
    readonly token: string,
}

interface LoadSoftwareModules {
    software: string
}

export function loadSoftwareModules(params: LoadSoftwareModules) {
  return (dispatch: Function, getState: Function) => {
    const url = 'software/module/view';
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    // api request for list software - action
    const request = {
      url,
      params,
      header: { Authorization: `Bearer ${token}` },
      onStart: modulesRequested.type,
      onSuccess: modulesReceived.type,
      onError: modulesRequestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

interface RegisterNewModuleParams {
  software: string;
  description: string;
}

export function registerNewModule(data: RegisterNewModuleParams) {
  return (dispatch: Function, getState: Function) => {
    const url = 'software/module/store';
    let { token }: tokenType = getState().entities.auth;

    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const request: ApiCallBegan = {
      url,
      method: 'POST',
      data,
      header: { Authorization: `Bearer ${token}` },
      onStart: modulesRequested.type,
      onSuccess: moduleAdded.type,
      onError: modulesRequestFailed.type,
    };

    dispatch(
      apiCallBegan(request),
    );
  };
}

export function modulesClearData() {
  return (dispatch: Dispatch): void => {
    dispatch(modulesClear());
  };
}

interface State {
    software: {
        modules: SoftwareModulesList
    }
}

export const getSoftwareModules = createSelector(
  (state: State) => state.software.modules,
  (modules: SoftwareModulesList) => modules,
);
