import {
  createSelector,
  createSlice,
  Dispatch,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { hello } from './auth';

type ListQttProps = {
  id: string,
  label: string,
  value: number,
}

type ListVersionProps = {
    CLIENTE: string,
    VERSAO: string,
    DATA: string,
    id: number,
}

type VersionProps = {
  listQtt: ListQttProps[],
  listQttDate: ListQttProps[],
  listVersion: ListVersionProps[],
  loadingQtt: boolean,
  loadingVersion: boolean,
}

const initialState = {
  listQtt: [],
  listQttDate: [],
  listVersion: [],
  loadingQtt: false,
  loadingVersion: false,
};

const slice = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    versionsQttRequested: (versions: Draft<VersionProps>): void => {
      versions.loadingQtt = true;
      versions.listQtt = [];
      versions.listQttDate = [];
    },
    versionsQttFailed: (versions: Draft<VersionProps>): void => {
      versions.loadingQtt = false;
    },
    versionsQttReceived: (versions: Draft<VersionProps>, actions: PayloadAction<any>): void => {
      versions.loadingQtt = false;
      const { response_data } = actions.payload;
      response_data?.map((data: any) => versions.listQtt.push({
        id: data.version,
        label: data.version,
        value: data.qtt,
      }));
    },
    versionsQttDateReceived: (versions: Draft<VersionProps>, actions: PayloadAction<any>): void => {
      versions.loadingQtt = false;
      const { response_data } = actions.payload;
      response_data?.map((data: any) => versions.listQttDate.push({
        id: data.version,
        label: data.version,
        value: data.qtt,
      }));
    },
    versionsListRequested: (versions: Draft<VersionProps>): void => {
      versions.loadingVersion = true;
      versions.listVersion = [];
    },
    versionsListFailed: (versions: Draft<VersionProps>): void => {
      versions.loadingVersion = false;
    },
    versionsListReceived: (versions: Draft<VersionProps>, actions: PayloadAction<any>): void => {
      versions.loadingVersion = false;
      const { response_data } = actions.payload;

      versions.listVersion = response_data.map((data: ListVersionProps, index: number) => {
        data.id = index;
        return data;
      });
    },
    versionsClearData: (versions: Draft<VersionProps>): void => {
      versions.listQtt = [];
      versions.listQttDate = [];
      versions.listVersion = [];
    },
  },
});

export default slice.reducer;

const {
  versionsQttRequested,
  versionsQttFailed,
  versionsQttReceived,
  versionsQttDateReceived,
  versionsListRequested,
  versionsListReceived,
  versionsListFailed,
  versionsClearData,
} = slice.actions;

const url = 'clients';

type tokenType = {
  readonly token: string,
}

type ParamsGraphicProps = {
    toDate?: string
    software_id: number,
    size?: number | string,
}

export function clearVersionsData() {
  return (dispatch: Dispatch) => {
    dispatch(versionsClearData());
  };
}

export function loadDataGraphic(params: ParamsGraphicProps) {
  return (dispatch: Dispatch, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const hasDate = params?.toDate;

    const request = {
      url: `${url}/qttVersion`,
      header: { Authorization: `Bearer ${token}` },
      onStart: versionsQttRequested.type,

      onSuccess: hasDate
        ? versionsQttDateReceived.type
        : versionsQttReceived.type,

      onError: versionsQttFailed.type,
      params,
    };

    dispatch(apiCallBegan(request));
  };
}

export function loadListVersion(software_id: number) {
  return (dispatch: Dispatch, getState: Function) => {
    // get token
    let { token }: tokenType = getState().entities.auth;

    // if you don't have a token, get the session hello
    if (!token) token = JSON.parse(<string>sessionStorage.getItem(hello));

    const request = {
      url: `${url}/listVersion`,
      header: { Authorization: `Bearer ${token}` },
      onStart: versionsListRequested.type,
      onSuccess: versionsListReceived.type,
      onError: versionsListFailed.type,
      params: {
        software_id,
      },
    };

    dispatch(apiCallBegan(request));
  };
}

type StateProps = { entities: { versions: VersionProps } };

export const getVersions = createSelector(
  (state: StateProps) => state.entities.versions,
  (versions: VersionProps) => versions,
);
