import { createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useModalStyles = makeStyles(() => createStyles(
  {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
));

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paper = styled.div`
  background-color: #fafafa;
  box-shadow: #303030;
  padding: 8px 16px 12px;
  border: none;
  outline: none;
  border-radius: 12px;
`;

export const Title = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`;

export const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
`;

export const ButtonGroup = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  height: 8rem;
`;
