import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadSyncs } from '../../store/synchronized/synchronized';
import { SynchronizedDataGrid } from './DataGrid';
import { Main } from '../../components/Main';
import { Header } from './Header';

export function Synchronized() {
  const dispatch = useDispatch();

  // load list
  useEffect(() => {
    dispatch(loadSyncs());
  }, []);

  /* component table */
  return (
    <Main
      title="Sincronizados"
      header={<Header />}
      content={<SynchronizedDataGrid />}
    />
  );
}
