import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import history from '../../services/history.services';

const BackButtonSyncDetails = () => (
  <Button
    size="large"
    onClick={() => {
      history.goBack();
    }}
    startIcon={<ArrowBackIcon color="primary" fontSize="large" />}
  />
);

export default BackButtonSyncDetails;
