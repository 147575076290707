import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    display: 'flex',
    paddingTop: '25px',
  },
  versionIcon: {
    fill: '#607d8b',
    position: 'relative',
    top: '0px',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  start: {
    justifyContent: 'start',
  },
  download: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#3c5cac',
  },
});
export default useRowStyles;
