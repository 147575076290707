import React, { ChangeEvent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Row from './Row';

interface Changelog {
  version: string,
  link: string,
  skipable: number,
  changelog: string,
  total: number,
  created_at: string,
}

type EventType = (React.MouseEvent<HTMLButtonElement, MouseEvent> | null);

interface UpdateTableProps {
  changelog: Changelog[],
  count: number,
  page: number,
  rowsPerPage: number,
  onChangeRowsPerPage: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onChangePage: (event: EventType, page: number) => void
}

export function UpdateTable(props: UpdateTableProps) {
  const {
    count,
    onChangeRowsPerPage,
    onChangePage,
    rowsPerPage,
    changelog,
    page,
  } = props;
  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={110}>
                Versão
              </TableCell>
              <TableCell align="center" width={110}>
                Data
              </TableCell>
              <TableCell align="center" width={50}>
                Obrigatório
              </TableCell>
              <TableCell align="center" width={200}>
                Download
              </TableCell>
              <TableCell align="left" width={200}>
                Changelogs
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {changelog.map((row: Changelog) => <Row key={row.version} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
}
