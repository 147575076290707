/*
*  config data grid columns and data
*/

import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { useSelector } from 'react-redux';
import LoadingDataGrid from '../../../components/LoadingDataGrid';
import CustomBadge from '../../../components/CustomBadge';
import { getSyncs } from '../../../store/synchronized/synchronized';

function getDate(params: GridCellParams) {
  return params.getValue('date') === null;
}

/*
* configure columns data grid
*/

const SyncColumnsList: GridColDef[] = [
  {
    field: 'count',
    headerName: 'Quantidade',
    width: 150,
    renderCell: (params: GridCellParams) => {
      const isNeverSynchronized = getDate(params);
      return (
        <>
          {!isNeverSynchronized ? (
            <CustomBadge
              badgeContent={params.value as number}
              icon={<CloudDoneIcon style={{ fill: '#00c853' }} />}
            />
          ) : (
            <CustomBadge
              badgeContent={params.value as number}
              icon={<CloudOffIcon color="secondary" />}
            />
          )}
        </>
      );
    },
  },
  {
    field: 'data_sync',
    headerName: 'Data de sincronização',
    sortable: false,
    width: 200,
    valueGetter: (params) => {
      const date = params.getValue('date');
      let dateFormatted: string | null = '';
      if (date) {
        dateFormatted = moment(new Date(date as string))
          .format('DD/MM/yyyy');
      }

      return `${dateFormatted || 'NUNCA'}`;
    },
  },
  {
    field: 'date',
    headerName: 'Mais',
    width: 150,
    renderCell: (params: GridCellParams) => {
      const date = params.value;
      // checking out of sync
      const isNeverSynchronized = date === null;
      const dateParam = isNeverSynchronized ? 'neverSynced' : date;

      return (
        <Link to={`/sync/${dateParam}`} style={{ textDecoration: 'none' }}>
          <Typography style={{ color: '#424242' }} variant="subtitle1">
            <Button
              disableElevation
              variant="outlined"
              color={isNeverSynchronized ? 'secondary' : 'primary'}
              size="small"
              endIcon={<LaunchIcon />}
            >
              detalhes
            </Button>
          </Typography>
        </Link>
      );
    },
  },
];

export function SynchronizedDataGrid() {
  // get values sync redux
  const {
    loading,
    listFilter,
    list,
    isFiltered,
  } = useSelector(getSyncs);

  if (loading) return <LinearProgress />;

  const ListSyncs = isFiltered ? listFilter : list;
  return (
    <div style={{
      display: 'flex',
      height: '100%',
    }}
    >
      <DataGrid
        components={{
          LoadingOverlay: LoadingDataGrid,
        }}
        pageSize={10}
        columnBuffer={3}
        disableColumnMenu
        disableDensitySelector
        disableSelectionOnClick
        autoHeight
        rows={ListSyncs}
        columns={SyncColumnsList}
        rowsPerPageOptions={[10, 25, 50, 100]}
        loading={loading}
      />
    </div>
  );
}
