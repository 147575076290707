import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import React from 'react';
import { Button, Tooltip, Zoom } from '@material-ui/core';
import moment from 'moment';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import clipboard from '../../../services/api/api.clipboard';

const columns: GridColDef[] = [
  {
    field: 'CLIENTE',
    headerName: 'Cliente',
    width: 250,
    renderCell: (params: GridCellParams) => {
      const description = params.value as string;
      const isLargeDescription = description.length < 20;
      return (
        <Tooltip
          placement="bottom"
          TransitionComponent={Zoom}
          title={description}
        >
          <p style={{ textTransform: 'uppercase' }}>
            { isLargeDescription
              ? description
              : `${description.substr(0, 20)}...`}
          </p>
        </Tooltip>
      );
    },
  },
  {
    field: 'COD_USUARIO',
    headerName: 'Cód.',
    width: 70,
    renderCell: (params: GridCellParams) => (<p>{params.value}</p>),
  },
  {
    field: 'USUARIO',
    headerName: 'Usuário',
    width: 200,
    renderCell: (params: GridCellParams) => {
      const description = params.value as string;
      return (
        <Tooltip
          placement="bottom"
          TransitionComponent={Zoom}
          title={description}
        >
          <p style={{ textTransform: 'uppercase' }}>{description}</p>
        </Tooltip>
      );
    },
  },
  {
    field: 'DESCRICAO',
    headerName: 'Descrição',
    description: 'Clique sobre a descrição para copiar',
    width: 400,
    renderCell: (params: GridCellParams) => {
      const description = params.value as string;
      return (
        <Button
          startIcon={(
            <Tooltip
              placement="bottom"
              TransitionComponent={Zoom}
              title="Copiar descrição"
            >
              <FileCopyIcon color="primary" />
            </Tooltip>
          )}
          onClick={() => clipboard({ copy: description })}
        >
          <Tooltip
            placement="bottom"
            TransitionComponent={Zoom}
            title={description}
          >
            <p>{`${description.substr(0, 35)}...`}</p>
          </Tooltip>
        </Button>
      );
    },
  },
  {
    field: 'FORMULARIO',
    headerName: 'Formularios',
    width: 200,
    renderCell: (params: GridCellParams) => (<p>{params.value}</p>),
  },
  {
    field: 'VERSAO',
    headerName: 'Versão',
    width: 120,
    renderCell: (params: GridCellParams) => (
      <Button
        startIcon={<InfoIcon style={{ fill: '#607d8b' }} />}
      >
        <p>{params.value}</p>
      </Button>
    ),
  },
  {
    field: 'DATA_ENVIADO',
    headerName: 'Enviado em',
    width: 200,
    renderCell: (params: GridCellParams) => {
      const date = moment(new Date(params.value as string)).format('DD/MM/YYYY HH:mm');
      return (<p>{date}</p>);
    },
  },
  {
    field: 'LINK_ERRO',
    headerName: 'Erro',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <a
        rel="noopener noreferrer"
        href={params.value as string}
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <Tooltip
          placement="bottom"
          TransitionComponent={Zoom}
          title="visualizar erro enviado"
        >
          <Button
            disableElevation
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<LaunchIcon />}
          >
            detalhes
          </Button>
        </Tooltip>
      </a>
    ),
  },
];

export default columns;
